import BaseRequest from './BaseRequest';

export default class RequestShowRooms extends BaseRequest {
    public roomType:string = "";

    constructor(roomTypeId:string) {
        super("showRooms");
        this.roomType = roomTypeId;
    }

    getData():any {
        let outObj = super.getData();
        outObj.data = this.roomType;
        return outObj;
    }
}

// {"action":"showRooms","data":"demo","id":1630410392778.1853}
