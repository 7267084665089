{
  "betDiapason": "Pariu / Miza",
  "yourBalance": "Soldul dumneavoastră",
  "min": "min",
  "max": "max",
  "auto": "auto",
  "using": "Modul de joc",
  "roomAmount": "Număr de camere",
  "rooms": "Camere",
  "room": "Cameră",
  "memberAmount": "Număr de participanți",
  "betLabelName": "Nume",
  "betLabelTime": "Ora",
  "Ok": "Bine",
  "betsAmount": "SUMĂ CÂȘTIGATĂ",
   
  "allBets": "TOATE PARIURILE",
  "myBets": "PARIURILE MELE",
  "history": "ISTORIC",
  "chat": "LISTĂ DE CONVERSAȚIE",
  "bet": "Pariu",
  "сhance": "Şansă",
  "win": "Câștig",
  "amountOnline": "Număr online",
  "chatPrompt": "Text",
  "send": "Trimite",
  "settings": "Setări",
  "changeAvatar": "Schimbă avatarul",
  "changeName": "Schimbă numele",
  "sound": "Sunet",
  "music": "Muzică",
  "animations": "Animații",
  "bonusBet": "Pariu bonus",
  "language": "Limbă",
  "toHome": "Acasă",
  "gettingBets": "OBȚINE PARIURI",
  "cancel": "Anulare",
  "inroom": "Sunteți în camera:",
  "data": "Data",
  "checkText": "Acest nume nu este disponibil, vă rugăm încercați din nou",
  "inputname": "Introduceți numele dumneavoastră",
  "nameTaken": "Acest nume de utilizator este deja luat, vă rugăm să încercați altul",
  "nameInvalid": "Eroare. Puteți utiliza literele a - z, cifrele 0 - 9 și semnul _. Numărul de caractere - de la 4 la 16",
  "wrongDiapason": "Eroare. \n Valoarea totală a pariurilor dumneavoastră pentru extragerea curentă a fost depășită",
  "default": "Conexiune pierdută \n Vă rugăm să reîncărcați jocul.",
  "InsufficientFunds": "Nu există bani în contul dumneavoastră. \n Vă rugăm să vă alimentați contul pentru a continua.",
  "SessionOverride": "Din păcate, sesiunile multiple nu sunt \n   acceptate în jocurile noastre",
  "shutdownNotification": "Întreținerea sistemului Ne pare rău, vom efectua întreținerea sistemului cât mai curând posibil. Vă rugăm să încheiați sesiunea curentă de joc și să ieșiți din joc. În timpul întreținerii, jocurile noastre vor fi indisponibile pentru o anumită perioadă, după care vom reveni pentru a vă încânta din nou!",
  "incorrectGIN": "Jocul este momentan indisponibil.",
  "maintenance": "Întreținerea sistemului. Ne pare rău, în prezent efectuăm întreținerea sistemului. Vom reveni în curând pentru a vă încânta cu jocurile noastre. Mulțumim",
  "sessionTimeout": "Sesiunea dumneavoastră a expirat din cauza inactivității",
  "ipBlocked": "Datorită legislației locale, acest joc nu este disponibil în țara dumneavoastră.",
  "notAvailable": "Not available"}