
import { defineComponent } from 'vue';
import store from '../store'
import Utils from '../Utils'
import { useSound } from '@vueuse/sound';
import jackpotSfx from '../assets/audio/jackpot.mp3';
import UtilsMixin from './UtilsMixin.vue'

export default defineComponent({
    mixins:[UtilsMixin],
    name: 'PanelJackpot',
    props: {},
    setup() {
        const jackpot = useSound(jackpotSfx, {volume: 1.0,interrupt: false}).sound;
        return {jackpot};
    },
    computed: {
        jackpotValue():string {
            return Utils.roundMoney(store.state.jackpot.pool);
        },
        // debug():string {
        //     return store.state.room.game.stage;
        // },
        jpStarsClass():string {
            // store.state.optionSound && this.jackpot.play()   //  if(?????)
            return "st"+store.state.jackpot.stars
        }
    }
});


