{
    "betDiapason": "Ставка / Ваш пул",
    "yourBalance": "Ваш баланс",
    "winner": "winner",
    "noWin": "no win",
    "min": "мин",
    "max": "макс",
    "auto": "авто",
    "using": "Игра использует",
    "roomAmount": "Кол-во комнат",
    "rooms": "КОМНАТЫ",
    "room": "Комната",
    "memberAmount": "Кол-во участников",
    "betsAmount": "СУММА ВЫИГРЫША",
    "allBets": "ВСЕ СТАВКИ",
    "myBets": "МОИ СТАВКИ",
    "history": "ИСТОРИЯ",
    "chat": "ЧАТ",
    "bet": "Ставка",
    "betLabelTime": "Время",
    "betLabelName":"Имя",
    "сhance": "Вероятность",
    "win": "Выигрыш",
    "amountOnline": "Кол-во онлайн",
    "chatPrompt": "Текст сообщения",
    "send": "Отправить",
    "settings": "Настройки",
    "changeAvatar": "Изменить аватар",
    "changeName": "Изменить имя",
    "sound": "Звук",
    "music": "Музыка",
    "animations": "Анимации",
    "bonusBet": "Бонус бет",
    "language": "Язык",
    "toHome": "На главную",
    "gettingBets": "ПОЛУЧЕНИЕ СТАВОК",
    "cancel": "Отмена",
    "inroom":"Вы в комнате:",
    "data":"Дата",
    "round": "Раунд",
    "hashTitle": "Cид текущего розыгрыша :",

    "seedServer":"СИД СЕРВЕРА",
    "seedPlayer1":"СИД ИГРОКА 1",
    "seedPlayer2":"СИД ИГРОКА 2 ",
    "sha512":"HASHED IN 512 ",
    "result":"Результат:",
    "whatIsFairPlay":"Что такое",

  "inputname": "Введите ваше имя",
  "nameTaken": "Это имя пользователя уже занято, попробуйте другое",  
  "nameInvalid": "Ошибка. Вы можете использовать a-z, 0-9 и _. Длина символов - от 4 до 16",
  "wrongDiapason": "Ошибка. \n Превышена общая сумма Ваших ставок в текущем розыгрыше",
  "default": "Ошибка соединения \n Пожалуйста, перезагрузите игру", 
  "InsufficientFunds": "Недостаточно средств. \n Пожалуйста,пополните счет, чтобы продолжныть игру",
  "SessionOverride": "К сожалению, мультисессии \n  запрещены в наших играх",  
  "shutdownNotification":"Профилактические работы. Приносим извинения, в ближайшее время мы будем проводить профилактические работы. Просьба завершить текущую игровую сессию и выйти из игры. Во время профилактических работ игры будут недоступны некоторое время, после чего мы вернемся и снова будем радовать Вас!",
  "incorrectGIN":"Игра в данный момент недоступна",
  "maintenance": "Системное обслуживание. Извините, пока мы проводим техническое обслуживание системы. Мы будем рады видеть Вас снова после завершения обслуживания. Спасибо",
  "sessionTimeout":"Ваш сеанс истек из-за неактивности",
  "ipBlocked":"В соответствии с местным законодательством эта игра недоступна в вашей стране",
  "notAvailable": "Not available"}