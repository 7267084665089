
import { defineComponent } from 'vue';
import i18n from '../i18n';
import store from '../store';
import { ref } from 'vue';
import { useSound } from '@vueuse/sound';
import Utils from '@/Utils';
// import backgroundSfx from '../assets/audio/background.mp3';
import btnSliderSfx from '../assets/audio/zajatie.mp3';
import btnPushSfx from '../assets/audio/najatie.mp3';
import Config from '@/Config';



export default defineComponent({
    name: 'Settings',
    props: ["sound"],   
    // data() { return {
    //     userName: store.state.user.name
    // }},
    computed: {
        checkHome():boolean{return Config.HOME_URL != "about:blank"},
        userName():string { return store.state.user.name.substring(0, Config.PLAYER_NAME_MAX_LENGTH); },
        settingsTitle():string { return i18n.global.t('settings'); },
        changeAvatarLabel():string { return i18n.global.t('changeAvatar'); },
        changeNameLabel():string { return i18n.global.t('changeName'); },
        bonusBetLabel():string { return i18n.global.t('bonusBet'); },
        languageLabel():string { return i18n.global.t('language'); },
        toHomeLabel():string { return i18n.global.t('toHome'); },
        optionSoundLabel():string { return i18n.global.t('sound'); },
        optionMusicLabel():string { return i18n.global.t('music'); },
        optionAnimLabel():string { return i18n.global.t('animations'); },
        optionSound():boolean { return store.state.optionSound; },        
        optionMusic():boolean { return store.state.optionMusic; },
        optionAnim():boolean { return store.state.optionAnim; },
        userAvatar():string { return require("../assets/avatars/" + Utils.getAvatarFileNameById(store.state.user.icon) + ".svg")}  
    },
    setup() {
        // const {sound} = useSound(backgroundSfx, {volume: 1.0,interrupt: false});
        const btnSlider = useSound(btnSliderSfx, {volume: 1.0,interrupt: false}).sound;
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;        
        return { btnPush, btnSlider};  //sound
    },
    methods: {
        clickClose():void {
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_NAME', false);
            store.commit('M_POPUP_IS_OPENED', false);
        },
        goToHome():void {
           store.commit('M_GOTOHOME', false);
        },
        clickChAvatar():void {
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_ICON_POPUP_IS_OPENED', !store.state.isOpenChangeAvatar);
            store.commit('M_POPUP_IS_OPENED', false);
            // console.log("click change icon");
        },
        clickChName():void {
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_NAME_POPUP_IS_OPENED', !store.state.isOpenChangeName);
            store.commit('M_POPUP_IS_OPENED', false);
            console.log("click change name");
        },
        clickBonusBet():void {
            store.state.optionSound && this.btnPush.play()
            console.log("click Bonus Bet");
        },
        clickLanguage():void {
            store.state.optionSound && this.btnPush.play()
            console.log("click Language");
            // i18n.global.locale.value = "ru"; // временно для теста локализации, потом сделать списком

            store.commit('M_CHANGE_LANGUAGE_POPUP_IS_OPENED',  !store.state.isOpenChangelanguage);
            store.commit('M_POPUP_IS_OPENED', false);
        },
        clickHome():void {
            store.state.optionSound && this.btnPush.play()
            console.log("click Home");
        },
        clickOptionSound():void {           
            this.btnSlider.play()
            
            store.commit('M_OPTION_SOUND', !store.state.optionSound);
            return 
        },
        clickOptionMusic():void {
            this.sound._loop = true
            // !store.state.optionMusic? this.sound.play():this.sound.stop();           
            store.state.optionMusic? this.sound.mute(true): this.sound.mute(false)    
            // store.state.optionMusic? this.sound.play(): this.sound.stop();         
            store.commit('M_OPTION_MUSIC', !store.state.optionMusic);
        },
        clickOptionAnim():void {
            store.state.optionSound && this.btnSlider.play()
            store.commit('M_OPTION_ANIM', !store.state.optionAnim);
        }
    }

});


