
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';
import { Tabs } from '../../store';
import {LayoutMode} from '../../store';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'TextInfo',
    computed: {
        isFairplay():boolean {
            return store.state.tab == Tabs.FairPlay && !!store.state.room.game.hash;
        },
        hashTitle():string {
            return  i18n.global.t('hashTitle');
        },
        nextSeed():string {
            return store.state.room.game.hash;
        },
        showingCopy(){
         return store.state.tab == Tabs.FairPlay
        },
        contentSrc():string {
            var langIdFairplay = ['ru','en','uk'].includes(i18n.global.locale.value)? i18n.global.locale.value : 'en';
            var langId = i18n.global.locale.value       
            switch(store.state.tab) {
                case Tabs.Info: return "info/rules_"+langId+".html";
                case Tabs.FairPlay: return "info/fairplay_"+langIdFairplay+".html";
                default: return "";
            }
        },
    },
     setup() {
        // const {sound} = useSound(backgroundSfx, {volume: 1.0,interrupt: false});
        // const btnSlider = useSound(btnSliderSfx, {volume: 1.0,interrupt: false}).sound;
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;        
        return { btnPush};  //sound
    },
    methods: {
        clickClose():void {
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Normal);
            store.commit('M_CHANGE_TAB', Tabs.AllBets);
        },        
        clickCopy():void {
            let str: any = store.state.room.game.hash.toString()
            navigator.clipboard.writeText(str)
                .then(() => {            
                })
                .catch(err => {  
                    console.log(err)              
                });
            },
    }
});


