import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import i18n from './i18n';
import Config from './Config';
import Net from './net/Net';
import RequestAuth from './net/RequestAuth';
import GameField from './gameField/GameField';
import device from "current-device";

Config.init();
i18n.global.locale.value = Config.LANGUAGE!;
var app:any|null;

const net = new Net(onConnected,notConnected);
let fullscreen_touchstart_y: number
const element:any = document.documentElement; 

function onConnected() {
      Config.fastLoading? hidePreloader():setTimeout(()=>{hidePreloader()},5000)
      let reqAuth = new RequestAuth(Config.SESSION_ID!);
                reqAuth.onReceived = onAuth;
                reqAuth.exec(); 
}

function hidePreloader(){
                document.getElementById('preloaderL')?.remove()
                document.getElementById('logoGamzix')?.remove()
                document.getElementById('preloaderP')?.remove()
                document.getElementById('circleLoading')?.remove()
                // @ts-ignore
                window.timerGoGo && clearInterval(window.timerGoGo)
}

function notConnected() {
    createVueApp();
}

function fullscreen (){
    element.addEventListener( "click", function(){     
        try {
            if (!document.fullscreenElement && device.type != 'desktop') {
                // window.scrollTo(0, 1);
                if(element.requestFullscreen) {
                    element.requestFullscreen();
                } else if(element?.webkitrequestFullscreen) {
                    element?.webkitRequestFullscreen();
                } else if(element.mozRequestFullscreen) {
                    element?.mozRequestFullScreen();
                }
            }
        } catch (error) {
            console.error("fullscreen errro", error)
        }     
   
    })
    
    // element.addEventListener( "touchstart", function(event){
    //      console.log( "  requestFullscreen  touchstart");
    //     fullscreen_touchstart_y = event.changedTouches[0].screenY
    // })

    // element.addEventListener( "touchend", function(event){   
    //     console.log( "  requestFullscreen  touchend");    
    //     let touchLength =  fullscreen_touchstart_y - event.changedTouches[0].screenY
    //         if ( fullscreen_touchstart_y && touchLength > 50){
    //             if (!document.fullscreenElement) {
    //                 element.requestFullscreen
    //                 ? element.requestFullscreen() : null
    //             }
    //         }    
    // })
}


function onAuth(authData:any) {
    // console.log("---- onAuth");
    fullscreen()
    createVueApp();
    if(GameField.instance == null) {
        GameField.instance = new GameField();
    }           
    authData.error?store.commit("M_ALERTMESSAGEY_TOTAL_DATA",{action:"reload",type:authData.error.code}):store.dispatch("AUTH_DATA", authData.data);
}

function createVueApp() {
    if(app == null) {
        app = createApp(App).use(i18n).use(store);
        app.mount('#app');
    }
}


