import "url-search-params-polyfill";

export default class Config {
    static HOST:string|null;
    static PORT:string|null;
    static LANGUAGE:string|null;
    static HOME_URL:string|null;
    static SESSION_ID:string|null;
    static IS_SEQURITY_WS:boolean;
    static IS_DEBUG:boolean;
    // static COLOR_SET = [0xFF0000, 0x0057FF , 0x00FF66, 0xBD00FF, 0xB5480B, 0xB1C827, 0xEBC608, 0x2D3299,  0xF59B9B, 0x3B6A3A, 0x5A0279, 0x00FFD1, 0x8EA7DA];
    static COLOR_SET = [0xFFE600,0x640082,0x0022E8,0xFF7C00,0xA1F200,0xA10076,0x00A7FF,0xFB9EA9,0x00FBFF,0xCB00D1,0x007BFF,0x00FF00,0x9E70FF,0x9600A1,0x7638FF,0x44837A,0x7ABACA,0x8200BF,0x3700FF,0x00C234,0xEA005F,0xAD00FC,0x080094,0x2F524E,0x310057 ];
    static USER_COLOR = 0xffff00;
    static INC_DEC_BET_STEP = 5;
    static PLAYER_NAME_MAX_LENGTH = 16;
    static IS_LOG_STATE:boolean;
    static IS_LOG_SOCET_SHOWING:boolean;
    static IS_LOG_DATE:boolean;
    static IS_LOG_STORE:boolean;
    static fastLoading:boolean;
    static scaleCanvas:number;

    static init() {
        Config.fastLoading = false;
        Config.scaleCanvas = 1.15;
        Config.IS_LOG_DATE = false
        Config.IS_LOG_STORE = false
        Config.IS_LOG_STATE = false
        Config.IS_LOG_SOCET_SHOWING = false;
        
        var urlParams = new URLSearchParams(window.location.search);
        var urlParams_href = new URLSearchParams(window.location.href).toString();       
        // if (urlParams_href.substr(0, 4) == "http") alert('no');
        // if (urlParams_href.substr(0, 5) == "https") alert('yes');

        Config.IS_LOG_SOCET_SHOWING && console.log(urlParams_href.toString().split('://')[0]);   
        Config.HOST = urlParams.get("host");
        Config.PORT = urlParams.get("port");
        Config.LANGUAGE = urlParams.get("lang") || "en";
        Config.HOME_URL = urlParams.get("home") || "about:blank";
        Config.SESSION_ID = urlParams.get("sid");
        Config.IS_SEQURITY_WS = urlParams_href.substr(0, 5)  == "https" || urlParams.get("wss") == "1";
        // Config.IS_SEQURITY_WS = urlParams.get("wss") == "1";
        Config.IS_DEBUG = urlParams.get("debug") == "true";       
        for (let i = 0; i < Config.COLOR_SET.length; i++) {
            let randInt = Math.floor( Config.COLOR_SET.length ); // 0...L Math.random()*
            if(randInt == Config.COLOR_SET.length) randInt = 0;
            let clr = Config.COLOR_SET.splice(randInt,1)[0];
            Config.COLOR_SET.push(clr);
        }
    }

    static getPlayerColor(index:number) {
        return Config.COLOR_SET[index % Config.COLOR_SET.length];
    }


}