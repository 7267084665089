import {gsap, Linear} from 'gsap';
import { Container, Sprite, Ticker } from 'pixi.js';
import UFO from './UFO';

export default class Radar extends Container {
    private RADIUS = 140;
    private sprite: Sprite;
    public isActive:boolean = false;

    constructor() {
        super();
        this.sprite = Sprite.from(require("../assets/gameField/scanner_arrow.svg"));
        this.sprite.anchor.set(0.5);
        this.addChild(this.sprite);
        this.alpha = 0;
    }

    public setAngle(angle:number) {
        this.sprite.rotation = angle;
        if(Math.random() < 0.1) this.createUFO();
    }

    public show() {
        if(this.alpha == 1) return;
        gsap.to(this, {alpha: 1, ease:Linear.easeNone, duration: 0.3});
    }

    public hide() {
        if(this.alpha == 0) return;
        gsap.to(this, {alpha: 0, ease:Linear.easeNone, duration: 0.3});
    }

    private createUFO() {
        var ufo = new UFO(this);
        var r = (this.RADIUS - 40) * Math.random() + 20;
        var a = this.sprite.rotation - Math.PI/2*1.05;
        ufo.x =  r * Math.cos(a);
        ufo.y =  r * Math.sin(a);
    }


}