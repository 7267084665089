import BaseRequest from './BaseRequest';

export default class RequestAuth extends BaseRequest {
    public sessionKey:string = "";

    constructor(sessionKey:string) {
        super("auth");
        this.sessionKey = sessionKey;
        this.startPing()
    }
    
    getData():any {
        let outObj = super.getData();
        outObj.data = {sessionKey:this.sessionKey}; // TODO   remove this row after Vova changed protocol
        outObj.sessionKey = this.sessionKey;
        return outObj;
    }
}

