{
  "betDiapason": "Pari / Votre cagnotte ",
  "yourBalance": "Votre solde",
  "min": "min",
  "max": "max",
  "auto": "auto",
  "using": "Le jeu utilise",
  "roomAmount": "Nombre de salles",
  "rooms": "Salles",
  "room": "Salle",
  "memberAmount": "Nombre de participants",
  "betLabelName": "Nom",
  "betLabelTime": "Heure",
  "Ok": "Ok",
  "betsAmount": "MONTANT DU GAIN",
   
  "allBets": "TOUS LES PARIS",
  "myBets": "MES PARIS",
  "history": "HISTORIQUE",
  "chat": "FORUM DE DISCUSSION",
  "bet": "Pari",
  "сhance": "Probabilité",
  "win": "Gain",
  "amountOnline": "Nombre en ligne",
  "chatPrompt": "Texte du message",
  "send": "Envoyer",
  "settings": "Paramètres",
  "changeAvatar": "Changer d'avatar",
  "changeName": "Changer de nom",
  "sound": "Son",
  "music": "Musique",
  "animations": "Animations",
  "bonusBet": "Pari bonus",
  "language": "Langue",
  "toHome": "À l’accueil",
  "gettingBets": "RÉCEPTION DES PARIS",
  "cancel": "Annulation",
  "inroom": "Vous êtes dans la salle :",
  "data": "Date",
  "checkText": "Ce nom n'est pas disponible, veuillez réessayer",
  "inputname": "Entrez votre nom",
  "nameTaken": "Ce nom d'utilisateur est déjà utilisé, veuillez en essayer un autre",
  "nameInvalid": "Une erreur s'est produite. Vous pouvez utiliser a-z, 0-9 et \"_\". Nombre de caractères de 4 à 16",
  "wrongDiapason": "Une erreur s'est produite. \n Le montant total de vos paris dans le tirage en cours a été dépassé",
  "default": "Connexion perdue \n Veuillez redémarrer le jeu.",
  "InsufficientFunds": "Il n'y a pas d'argent sur votre compte. \n Veuillez alimenter votre compte pour continuer.",
  "SessionOverride": "Malheureusement, l'exécution simultanée de plusieurs sessions \n   n'est pas disponible dans nos jeux.",
  "shutdownNotification": "Maintenance du système. Veuillez nous excuser, nous allons nous occuper de la maintenance du système sous peu. Terminez la session de jeu en cours et quittez le jeu. Pendant les travaux de maintenance, les jeux seront indisponibles pendant un certain temps. Nous serons heureux de vous revoir après avoir terminé les travaux !",
  "incorrectGIN": "Le jeu est actuellement indisponible.",
  "maintenance": "Maintenance du système. Nous sommes désolés, nous effectuons actuellement une maintenance sur le système. Nous serons heureux de vous revoir après avoir terminé les travaux ! Merci",
  "sessionTimeout": "Votre session a expiré en raison d'un manque d'activité",
  "ipBlocked": "Conformément à la législation locale, ce jeu n'est pas disponible dans votre pays.",
  "notAvailable": "Not available"}