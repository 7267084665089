
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store'
import BetItem from './BetItem.vue';

export default defineComponent({
    name: 'AllBets',
    components: {
        BetItem
    },
    computed: {
        store() { return store },
        betLabel():string { return i18n.global.t('bet'); },
        сhanceLabel():string { return i18n.global.t('сhance'); },
        winLabel():string { return i18n.global.t('win'); },
        betLabelName():string { return i18n.global.t('betLabelName'); },
        // currentBets():Array<any> { return store.state.room.game.bets },        
        currentBets():Array<any> { return store.state.room.game.bets.slice().reverse(); },
    }
});


