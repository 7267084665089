{
  "betDiapason": "Ставка / Сіздің пул",
  "yourBalance": "Сіздің теңгеріміңіз",
  "min": "мин",
  "max": "макс",
  "auto": "авто",
  "using": "Ойын қолдануда",
  "roomAmount": "Бөлмелер саны",
  "rooms": "Бөлмелер",
  "room": "Бөлме",
  "memberAmount": "Қатысушылар саны",
  "betLabelName": "Аты",
  "betLabelTime": "Уақыты",
  "Ok": "Ок",
  "betsAmount": "ҰТЫС СОМАСЫ",
   
  "allBets": "БАРЛЫҚ ҰТЫСТАР",
  "myBets": "МЕНІҢ ҰТЫСТАРЫМ",
  "history": "ТАРИХ",
  "chat": "ЧАТ",
  "bet": "Ұтыс",
  "сhance": "Ықтималдылық",
  "win": "Ұту",
  "amountOnline": "Онлайн саны",
  "chatPrompt": "Хабарламалар мәтіні",
  "send": "Жөнелту",
  "settings": "Баптаулар",
  "changeAvatar": "Аватарды ауыстыру",
  "changeName": "Атын ауыстыру",
  "sound": "Даусы",
  "music": "Музыка",
  "animations": "Анимациялар",
  "bonusBet": "Бонус бет",
  "language": "Тіл",
  "toHome": "Басты бетке көшу",
  "gettingBets": "ҰТЫС АЛУ",
  "cancel": "Бас тарту",
  "inroom": "Сіз бөлмедесіз:",
  "data": "Күні",
  "checkText": "Бұл ат қою қол жетімсіз, тағы да көріңіз",
  "inputname": "Атыңызды жазыңыз",
  "nameTaken": "Бұл есім бос емес, басқасын жазып көріңіз",
  "nameInvalid": "Қате. Сіз a-z, 0-9 және _ қолдана аласыз. Символдар ұзындығы - 4-16",
  "wrongDiapason": "Қате. \n Ағымдағы ұтыс ойынында сіздің қоған ақшаңыздың жалпы сомасы асып кетті",
  "default": "Қосылыс жоғалды \n Өтінеміз, ойынды өшіріп-қосыңыз.",
  "InsufficientFunds": "Сіздің шотыңызда ақша жоқ. \n Өтінеміз, ойынды жалғастыру үшін, шотыңызды толтырыңыз.",
  "SessionOverride": "Өкінішке орай, бірнеше сессия бір уақытта қосылды \n   біздің ойындарда қол жетімсіз.",
  "shutdownNotification": "Жүйелік жұмыстар Кешірім сұраймыз, жақын арада біз жүйелік жұмыстар жүргіземіз. Ағымдағы ойын сессиясын аяқтап, ойыннан шығыңыз. Профилактикалық жұмыстар жүргізу уақытында біраз уақыт ойын ойнай алмайсыз. Жұмыстар аяқталғаннан кейін біз сізді қайта көргенімізге қуанышты боламыз!",
  "incorrectGIN": "Дәл қазір ойын ойналмайды.",
  "maintenance": "Жүйеде техникалық жұмыстар. Кешірім сұраймыз, қазіргі уақытта жүйеде техникалық жұмыстар жүргізілуде. Жұмыстар аяқталғаннан кейін біз сізді қайта көргенімізге қуанышты боламыз! Алғысымызды білдіреміз",
  "sessionTimeout": "Сіздің сеанс белсенділік болмағаннан кейін өшірілді",
  "ipBlocked": "Жергілікті заңнамаға сәйкес бұл ойын сіздің елде ойналмайды.",
  "notAvailable": "Not available"}