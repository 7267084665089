import { Container, Graphics, Ticker } from 'pixi.js';

export default class UFO extends Graphics {
    private container:Container|null;
    private decay:boolean;

    constructor(cnt:Container,decay: boolean = true ) {       
        super();       
        this.decay = decay
        this.container = cnt;
        this.beginFill(0xF9D128);
        this.drawCircle(0, 0, Math.random()*2.5 + 2);
        this.endFill();
        this.container.addChild(this);
        this.cacheAsBitmap = true;
        this.alpha = Math.random()*0.4 + 0.5;
        Ticker.shared.add(this.tick, this);
    }

    private tick() {
        this.alpha -= 0.01;
        if(!this.decay){
            this.alpha <= 0.02 &&  (this.alpha = Math.random()*0.9);            
        }
        if(this.alpha <= 0.01) {
            Ticker.shared.remove(this.tick, this);
            this.container!.removeChild(this);
            this.cacheAsBitmap = false;
            this.container = null;
        }
    }

    public destroy() {
        //TO DO   Ticker.shared.remove(this.tick, this);
    }
 

}