{
  "betDiapason": "शर्त / आपका पूल",
  "yourBalance": "आपकी शेष राशि",
  "min": "न्यूनतम ",
  "max": "अधिकतम ",
  "auto": "स्वचालित ",
  "using": "खेल में उपयोग होता है ",
  "roomAmount": "कमरों की संख्या",
  "rooms": "कमरे ",
  "room": "कमरा ",
  "memberAmount": "प्रतिभागियों की संख्या",
  "betLabelName": "नाम ",
  "betLabelTime": "समय ",
  "Ok": "ओके ",
  "betsAmount": "जीत की राशि ",
  
  "allBets": "सभी दाँव ",
  "myBets": "मेरे दाँव ",
  "history": "इतिहास ",
  "chat": "चैट ",
  "bet": "दाँव ",
  "сhance": "संभावना",
  "win": "जीत",
  "amountOnline": "ऑनलाइन की संख्या ",
  "chatPrompt": "संदेश शब्द",
  "send": "भेजना ",
  "settings": "सेटिंग ",
  "changeAvatar": "अवतार बदलना ",
  "changeName": "नाम बदलना ",
  "sound": "आवाज़ ",
  "music": "संगीत ",
  "animations": "एनिमेशन",
  "bonusBet": "बोनस की शर्त",
  "language": "भासः ",
  "toHome": "होम ",
  "gettingBets": "दाँव प्राप्त करना",
  "cancel": "रद्द करना",
  "inroom": "क्या आप कमरे में हैं:",
  "data": "दिनाँक ",
  "checkText": "यह नाम उपलब्ध नहीं है, कृपया पुनः प्रयास करें",
  "inputname": "अपना नाम दर्ज करें",
  "nameTaken": "यह उपयोगकर्ता नाम पहले ही लिया जा चुका है, कृपया दूसरा प्रयास करें",
  "nameInvalid": "त्रुटि आप a-z, 0-9 और _ का उपयोग कर सकते हैं। वर्ण की लंबाई - 4 से 16 . तक",
  "wrongDiapason": "त्रुटि \n वर्तमान ड्राइंग में आपके दाँव की कुल राशि पार हो गयी है",
  "default": "कनेक्शन खोया गया  \n कृपया खेल फिर से शुरू करें।",
  "InsufficientFunds": "आपके खाते में पैसा नहीं है। \n कृपया जारी रखने के लिए अपने खाते में पैसे डालें।",
  "SessionOverride": "दुर्भाग्य से, एक ही समय में कई सत्र चल रहे हैं \n   हमारे खेलों में उपलब्ध नहीं है।",
  "shutdownNotification": "सिस्टम की मरम्मत हो रही है। क्षमा करें। निकट भविष्य में हम सिस्टम की सर्विसिंग शुरू कर देंगे। वर्तमान खेल सत्र समाप्त करें और खेल से बाहर निकलें।  निवारक मेंटेनेंस के समय खेल कुछ समय के लिए अनुपलब्ध रहेंगे। मरम्मत पूरी करने के बाद आपको फिर से देखकर हमें खुशी होगी!",
  "incorrectGIN": "खेल वर्तमान में अनुपलब्ध है।",
  "maintenance": "सिस्टम की मरम्मत और रखरखाव। हमें खेद है, हम वर्तमान में सिस्टम की मरम्मत और तकनीकी सर्विसिंग कर रहे हैं। सेवा पूरी करने के बाद आपको फिर से देखकर हमें खुशी होगी। आपको धन्यवाद",
  "sessionTimeout": "निष्क्रियता के कारण आपका सत्र समाप्त हो गया है",
  "ipBlocked": "स्थानीय कानूनों के कारण, यह गेम आपके देश में उपलब्ध नहीं है।",
  "notAvailable": "Not available"}