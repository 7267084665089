
import { defineComponent } from 'vue';
import i18n from '../i18n';
import store from '../store';
import { Tabs } from '../store';
import {LayoutMode} from '../store';

export default defineComponent({
    name: 'PanelJackpot',
    props: {},
    computed: {
        fairPlay():string {
            return '' //i18n.global.t('using');
        }
    },
    methods: {
        clickFairPlay():void {
            store.commit('M_CHANGE_TAB', Tabs.FairPlay);
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Expand);
        }
    }
});


