{
  "betDiapason": "Einsatz / Dein Pool",
  "yourBalance": "Dein Kontostand",
  "min": "min.",
  "max": "max.",
  "auto": "Auto",
  "using": "Spielanwendungen",
  "roomAmount": "Anzahl der Räume",
  "rooms": "Räume",
  "room": "Räume",
  "memberAmount": "Zahl der Teilnehmer",
  "betLabelName": "Name",
  "betLabelTime": "Zeit",
  "Ok": "OK",
  "betsAmount": "GEWINNBETRAG",
  
  "allBets": "ALLE WETTEN",
  "myBets": "MEINE WETTEN",
  "history": "HISTORIE",
  "chat": "CHAT",
  "bet": "Wette",
  "сhance": "Wahrscheinlichkeit",
  "win": "Gewinn",
  "amountOnline": "Anzahl online",
  "chatPrompt": "Text",
  "send": "Senden",
  "settings": "Einstellungen",
  "changeAvatar": "Avatar ändern",
  "changeName": "Name ändern",
  "sound": "Ton",
  "music": "Musik",
  "animations": "Animationen",
  "bonusBet": "Bonuswette",
  "language": "Sprache",
  "toHome": "Start",
  "gettingBets": "WETTEN ERHALTEN",
  "cancel": "Abbrechen",
  "inroom": "Du bist in folgendem Raum:",
  "data": "Datum",
  "checkText": "Dieser Name ist nicht verfügbar, bitte versuche es noch einmal",
  "inputname": "Gib deinen Namen ein",
  "nameTaken": "Dieser Benutzername ist bereits vergeben, bitte versuche es mit einem anderen.",
  "nameInvalid": "Fehler. Du kannst folgende Zeichen verwenden: a-z, 0-9 und _. Zeichenlänge - 4 bis 16 Zeichen",
  "wrongDiapason": "Fehler. \n Der Gesamtbetrag deiner Einsätze in der aktuellen Ziehung wurde überschritten.",
  "default": "Verbindung unterbrochen \n Bitte lade das Spiel neu.",
  "InsufficientFunds": "Es befindet sich kein Geld auf deinem Konto. \n Bitte lade dein Konto auf, um fortzufahren.",
  "SessionOverride": "Leider werden Multisessions in \n   unseren Spielen nicht unterstützt.",
  "shutdownNotification": "Sorry, wir führen eine Systemwartung durch. Wir sind so schnell wie möglich wieder da. Bitte beenden deine aktuelle Spielsitzung und verlasse das Spiel. Während der Wartungsarbeiten werden unsere Spiele für einige Zeit nicht verfügbar sein, danach werden wir wieder für dich da sein!",
  "incorrectGIN": "Das Spiel ist derzeit nicht verfügbar.",
  "maintenance": "Systemwartung. Leider führen wir derzeit eine Systemwartung durch. Wir werden dich schon bald wieder mit unseren Spielen erfreuen. Vielen Dank",
  "sessionTimeout": "Deine Sitzung ist aufgrund von Inaktivität abgelaufen.",
  "ipBlocked": "Aufgrund der lokalen Gesetzgebung ist dieses Spiel in deinem Land nicht verfügbar.",
  "notAvailable": "Not available"}