import BaseRequest from './BaseRequest';

export default class RequestBetHistory extends BaseRequest {

    constructor( seed:string) {
        super("userRounds");
    }
    

}
