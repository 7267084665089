import { Container, Graphics } from 'pixi.js';
import {gsap, Sine, Expo} from 'gsap';

export default class YellowRay extends Container {
    constructor() {
        super();
        this.cacheAsBitmap = true;
        // ray: #F9D128, d=5px
        // circle: #F9D128, r=11px
        var line = new Graphics();
        line.lineStyle({color:0xF9D128, width: 5});
        line.lineTo(0, -140);
        this.addChild(line);

        var circle = new Graphics();
        circle.beginFill(0xF9D128);
        circle.drawCircle(0, 0, 6);
        circle.endFill();
        this.addChild(circle);
    }

    public setAngle(angle:number, animTime = 0, onComplete?:()=>void, isLinear:boolean = false) {
        if(animTime > 0) gsap.to(this, {rotation:angle, duration: animTime, onComplete:()=>{
            if(onComplete != null) onComplete();
        }, ease: isLinear ? Sine.easeOut : Expo.easeOut});
        else this.rotation = angle;
    }
    
}