
import { defineComponent } from 'vue';
import store, { MutationType, Stage } from '../store'
import i18n from '../i18n';
import Utils from '../Utils'
import { useSound } from '@vueuse/sound';
import {gsap} from 'gsap';
export default defineComponent({
    name: 'AlertInGame',
    props: {},
    computed: {        
        maxBetA():string {   
            return  i18n.global.t('wrongDiapason')
        },
        betValue():string {   
            return Utils.roundMoney(store.state.betValue);
        },
        showing():boolean {   
            if( store.state.isMaxBet ){  //store.state.betValue == store.state.totalBetInRound
                gsap.to(".pAlertGameCnt", {
                        startAt: {opacity: 1},                       
                        opacity:1,    
                        duration: 1,
                        onComplete:()=>{
                            gsap.to(".pAlertGameCnt", {
                                    startAt: {opacity: 1},                       
                                    opacity:0,    
                                    duration: 2,
                                    onComplete:()=>{
                                    store.state.isMaxBet = false
                                    }
                            }); 
                        }
                }); 
            }
            return  store.state.isMaxBet
        }
        
    },
   
});


