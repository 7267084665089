import GameField from './GameField';
import store from '../store';
import { BetVO } from '@/types';
import Config from '@/Config';



export default class GameController {
    private bets!:Array<BetVO>;

    constructor() {
        this.bets = [];
    }

    public doTrade() {
        Config.IS_LOG_STATE && console.log("$$ doTrade");
        clearInterval(store.state.timeIDRoom);
        store.state.timeRoom = Number(store.state.room.game.time.left)
        store.state.timeIDRoom = setInterval( ()=>{                        
            store.state.timeRoom -= 1000;
        }, 1000);
        
        if(store.state.isAutoPlay && store.state.totalBetInRound == 0 ) {
            store.dispatch("DO_BET");
            store.state.isAutoClickDoBet = true;
        }
        
        // this.clear();
        GameField.instance.radarOn();
    }

    public doLanding(durMs:number) { 
        Config.IS_LOG_STATE && console.log("$$ doLanding, "+durMs);
        this.clear();
        store.state.isBetAnimation = false
        GameField.instance.radarOff();

        this.fillBets();
        GameField.instance.setAddedBets(this.bets);
        GameField.instance.setCopyAddedBets(this.bets);
        GameField.instance.startDiskSectoring(durMs, ()=>{});
    }

    public doRoll(durMs:number) {
        Config.IS_LOG_STATE && console.log("$$ doRoll, "+durMs);
        GameField.instance.radarOff();
        store.state.timeIDRoom && clearInterval(store.state.timeIDRoom)
        durMs = store.state.room.game.time.left;
        let A = this.calcAngle();
        this.checkBetsForRollStage();
        GameField.instance.startDiskRolling(durMs/1000, A);
        // GameField.instance.dickUpdateTime(durMs/1000);
    }

    public doAward() {
        Config.IS_LOG_STATE && console.log("$$ doAward");
        GameField.instance.radarOff();
        this.checkBetsForAwardStage();
    }


    public clear() {
        Config.IS_LOG_STATE && console.log("$$ clear");
        // store.state.isAutoPlay = false
        this.bets.length = 0;
        GameField.instance.clearRay();
        GameField.instance.diskToStart();
        GameField.instance.clearBets();
        GameField.instance.dickStopAndClear()
    }

    /////////////////////////////////////

    private calcAngle() {
        Config.IS_LOG_STATE && console.log("$$ calcAngle:"+store.state.room.game.arrowPosition);
        
        let dbPI = Math.PI*2;
        let P = Math.floor(store.state.room.game.arrowPosition / dbPI);
        let R = store.state.room.game.arrowPosition % dbPI;
        return dbPI - R + P*dbPI;
    }

    private checkBetsForRollStage() {
        Config.IS_LOG_STATE && console.log("$$ checkBetsForRollStage, "+this.bets.length);
        if(!this.bets.length) {
            this.fillBets();
            GameField.instance.setAddedBets(this.bets);
            GameField.instance.instantDrawSectors();
        }
    }

    private checkBetsForAwardStage() {
        Config.IS_LOG_STATE && console.log("$$ checkBetsForAwardStage, "+this.bets.length);
        if(!this.bets.length) {
            this.checkBetsForRollStage();
            let A = this.calcAngle();
            GameField.instance.instantDiskPosition(A);
        }
        GameField.instance.clearText();   
    }

    // public fillBets() {
    //     // Config.IS_LOG_STATE && console.log("fillBets, "+store.state.room.game.bets.length);
    //     // console.log(store.state.room.game.bets.length);   
    //     store.state.room.game.bets.map((bet, i) => {
    //         console.log(bet,i);
            
    //     })     
    //     for (let i = 0; i < store.state.room.game.bets.length; i++) {
                     
    //         const item = store.state.room.game.bets[i];
    //         let bet:BetVO = {
    //             id: ""+i,
    //             name: item.player.name,
    //             color: item.me ? 0xFFFF00 : item.fail ? 0x00000 : Config.getPlayerColor(i),
    //             part: item.part,
    //             value: item.amount,
    //             bisector: 0
    //         };
    //         // @ts-ignore 
    //         if(store.state.room.game.bets?.fail) {
    //             console.log(Config.getPlayerColor(i));                
    //             continue
    //         }

    //         this.bets.push(bet);
    //     }
    //     return this.bets
    // }

    public fillBets() {
        ////console.log("fillBets, "+store.state.room.game.bets.length);
        const bets = store.state.room.game.bets;
        // store.state.room.game.bets.map((bet, i) => {
        //             console.log(bet,i);
                    
        // })
        // @ts-ignore
        return this.bets = bets.map((bet, i) => ({
            fail:bet.fail,
            id: ""+i,
            name: bet.player.name,
            color: bet.me ? 0xFFFF00 : Config.getPlayerColor(i),
            part: bet.part,
            value: bet.amount,
            bisector: 0
        })).filter(bet => !bet.fail)
    }
    
    
}