import BaseRequest from './BaseRequest';

export default class RequestRoomEnter extends BaseRequest {
    public roomId:string = "";

    constructor(roomId:string) {
        super("enter");
        this.roomId = roomId;
    }

    getData():any {
        let outObj = super.getData();
        outObj.data = this.roomId;
        return outObj;
    }
}

// {"action":"enter","data":1014,"id":1630410393345.5657}