{
  "betDiapason": "လောင်းကြေး / သင့်အလယ်ပုံ",
  "yourBalance": "သင့်လက်ကျန်ငွေ",
  "min": "အနည်းဆုံး",
  "max": "အများဆုံး",
  "auto": "အော်တို",
  "using": "ဂိမ်းအသုံးပြုသည်",
  "roomAmount": "အခန်းအရေအတွက်",
  "rooms": "အခန်းများ",
  "room": "အခန်း",
  "memberAmount": "ပါဝင်သူ အရေအတွက်",
  "betLabelName": "အမည်",
  "betLabelTime": "အချိန်",
  "Ok": "အိုကေ",
  "betsAmount": "နိုင်သည့်ပမာဏ",
   
  "allBets": "လောင်းကြေးအားလုံး",
  "myBets": "ကျွန်ုပ်၏ လောင်းကြေးများ",
  "history": "ရာဇဝင်",
  "chat": "ချက်တင်",
  "bet": "လောင်းကြေး",
  "сhance": "အခွင့်အရေး",
  "win": "အနိုင်ရသည်",
  "amountOnline": "အွန်လိုင်းရှိ အရေအတွက်",
  "chatPrompt": "စာသား",
  "send": "ပေးပို့မည်",
  "settings": "ဆက်တင်",
  "changeAvatar": "ရုပ်ပုံပြောင်းမည်",
  "changeName": "အမည်ပြောင်းမည်",
  "sound": "အသံ",
  "music": "ဂီတ",
  "animations": "အန်နီမေးရှင်းများ",
  "bonusBet": "ဘောနပ်စ်ကြေ",
  "language": "ဘာသာစကား",
  "toHome": "ပင်မ",
  "gettingBets": "လောင်းကြေးများ ရယူခြင်း",
  "cancel": "ပယ်ဖျက်မည်",
  "inroom": "သင်ရှိနေသည့် အခန်းမှာ -",
  "data": "နေ့စွဲ",
  "checkText": "ဤအမည်ကို မရနိုင်ပါ။ ထပ်ကြိုးစားပေးပါ",
  "inputname": "သင့်အမည်ကိုထည့်ပါ",
  "nameTaken": "ဤအမည်ကို ယူထားပြီး ဖြစ်ပါသည်။ နောက်တစ်ခု ကြိုးစားပေးပါ",
  "nameInvalid": "ချို့ယွင်းချက် az၊ 0-9 နှင့် _ တို့ကို သုံးနိုင်ပါသည်။ စာလုံးအရေအတွက် - ၄ မှ ၁၆ လုံး",
  "wrongDiapason": "ချို့ယွင်းချက် \n လက်ရှိအကြိမ်တွင် သင့်လောင်းကြေး ပမာဏမှာ ကျော်လွန်သွားပါပြီ။",
  "default": "ချိတ်ဆက်မှု ပြတ်သွားပါသည် \n ဂိမ်းကို ပြန်ဖွင့်ပေးပါ။",
  "InsufficientFunds": "သင့်အကောင့်ထဲတွင် ပိုက်ဆံမရှိပါ။ \n ဆက်ကစားနိုင်ရန် သင့်အကောင့်ကို ငွေဖြည့်ပေးပါ။",
  "SessionOverride": "ကံမကောင်းစွာဖြင့် ဆက်ရှင်များစွာ ဝင်ခြင်းကို \n   ကျွန်ုပ်တို့၏ ဂိမ်းများတွင် မပြုလုပ်နိုင်ပါ",
  "shutdownNotification": "စနစ် ပြုပြင်ထိန်းသိမ်းမှု စိတ်မကောင်းပါ စနစ် ပြုပြင်ထိန်းသိမ်းမှုကို ကျွန်ုပ်တို့ တတ်နိုင်သမျှ အမြန်ဆုံး ပြုလုပ်ပါမည်။ လက်ရှိဂိမ်းဆက်ရှင်ကို အပြီးသတ်ကာ ဂိမ်းမှထွက်ပေးပါ။ ပြုပြင်ထိန်းသိမ်းစဥ်အတွင်း အချိန်အနည်းငယ်ကြာအောင် ကျွန်ုပ်တို့ ဂိမ်းများကို ကစားနိုင်မည် မဟုတ်ဘဲ ယင်းနောက်တွင် သင့်စိတ်တိုင်းကျ ပြန်လည်ကစားနိုင်မည် ဖြစ်ပါသည်။",
  "incorrectGIN": "လက်ရှိတွင် ဂိမ်းကို မကစားနိုင်ပါ။",
  "maintenance": "စနစ် ပြုပြင်ထိန်းသိမ်းမှု။ စိတ်မကောင်းပါ။ လက်ရှိတွင် စနစ် ပြုပြင်ထိန်းသိမ်းမှုကို လုပ်ဆောင်နေပါသည်။ မကြာမီတွင် ကျွန်ုပ်တို့၏ ဂိမ်းများကို သင်ပြန်လည် ကစားနိုင်မည် ဖြစ်ပါသည်။ ကျေးဇူးတင်ပါသည်",
  "sessionTimeout": "လုပ်ဆောင်မှု မရှိခြင်းကြောင့် သင့်ဆက်ရှင် ကုန်သွားပါသည်",
  "ipBlocked": "ဒေသတွင်း ဥပဒေပြဌာန်းချက်ကြောင့် ဤဂိမ်းကို သင့်နိုင်ငံတွင် မကစားနိုင်ပါ။",
  "notAvailable": "Not available"}