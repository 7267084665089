
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../../assets/audio/najatie.mp3';

// import ChName from './ChatItem.vue';

export default defineComponent({
    name: 'ChangeName',   
    data() {
        return  {
            newName: "",
            isPrompt: true,
            inCorName: true
        }
    },
   
    created() {
        // console.log('     created    ');        
        this.newName = i18n.global.t('inputname');
    },
    updated() {  
        if(this.isPrompt) this.newName = i18n.global.t('chatPrompt');
    },
    computed: {
        Ok():string { return i18n.global.t(' Ok'); },
        cancel():string { return i18n.global.t('cancel'); },
        checkText():string {        
             if(store.state.user.nameInvalid) return i18n.global.t('nameInvalid');
             if(store.state.user.nameTaken) return i18n.global.t('nameTaken');
             else return ''
        }  
    },
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    methods: {       
        onFocus() {
            if(this.isPrompt) {
                this.newName = "";
                this.isPrompt = false;
            }
        },
        onBlur() {
            if(this.newName == "" && !this.isPrompt) {
                this.newName = i18n.global.t('chatPrompt');
                this.isPrompt = true;
            }
        },
        sendNewName(){    
            store.state.optionSound && this.btnPush.play()            
            store.dispatch('CHANGE_NAME', this.newName)            
            this.clearInput()
            },
        sendCancel(){
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_NAME_POPUP_IS_OPENED', !store.state.isOpenChangeName);
            store.commit('M_POPUP_IS_OPENED', true);
            this.clearInput();
        },
        clearInput (){
             this.newName = "";
             this.isPrompt = true           
             this.newName = i18n.global.t('inputname');
        }

    }
});


