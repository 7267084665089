import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "blockJ" }
const _hoisted_2 = { class: "blockR" }
const _hoisted_3 = { class: "blockL" }
const _hoisted_4 = { class: "blockF" }
const _hoisted_5 = { class: "blockS" }
const _hoisted_6 = { class: "blockP" }
const _hoisted_7 = { class: "blockB" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelJackpot = _resolveComponent("PanelJackpot")!
  const _component_RoomChooser = _resolveComponent("RoomChooser")!
  const _component_TableBlock = _resolveComponent("TableBlock")!
  const _component_GameFieldCmp = _resolveComponent("GameFieldCmp")!
  const _component_PanelBet = _resolveComponent("PanelBet")!
  const _component_PanelGame = _resolveComponent("PanelGame")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_AlertG = _resolveComponent("AlertG")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_FpCheckBet = _resolveComponent("FpCheckBet")!
  const _component_ChangeName = _resolveComponent("ChangeName")!
  const _component_ChangeAvatar = _resolveComponent("ChangeAvatar")!
  const _component_Changelanguage = _resolveComponent("Changelanguage")!
  const _component_SocketClosed = _resolveComponent("SocketClosed")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", {
      class: ["mainContainer", {expand:_ctx.isExpanded}],
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeRoomsAndMusicStart && _ctx.closeRoomsAndMusicStart(...args)))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_PanelJackpot)
      ]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_RoomChooser)
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_TableBlock)
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_GameFieldCmp, { soundBack: _ctx.sound }, null, 8, ["soundBack"])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_PanelBet)
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_PanelGame)
      ]),
      _createVNode("div", _hoisted_7, [
        _createVNode(_component_Footer)
      ])
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened_flex:  _ctx.isMaxBet}]
    }, [
      _createVNode(_component_AlertG)
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened:_ctx.isPopupOpened}]
    }, [
      _createVNode(_component_Settings, { sound: _ctx.sound }, null, 8, ["sound"])
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened:_ctx.isMyBetFpOpened}]
    }, [
      _createVNode(_component_FpCheckBet)
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened_flex:  _ctx.isOpenChName}]
    }, [
      _createVNode(_component_ChangeName)
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened_flex:  _ctx.isOpenAvatar}]
    }, [
      _createVNode(_component_ChangeAvatar)
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened_flex:  _ctx.isOpenChangelanguage}]
    }, [
      _createVNode(_component_Changelanguage)
    ], 2),
    _createVNode("div", {
      class: ["blockPopup", {opened_flex:  _ctx.isSocketClosed}]
    }, [
      _createVNode(_component_SocketClosed)
    ], 2)
  ], 64))
}