
import { defineComponent } from 'vue';
import i18n from '../i18n';
import store from '../store';
import { Tabs } from '../store';
import {LayoutMode} from '../store';

const maxShaLength = 13;
const rotations = { min: 3, max: 6 };
const maxNumber = parseInt(Array.from({ length: maxShaLength }).map(() => 'f').join(''), 16);


export default defineComponent({
    name: 'FpCheckBet',
    props: {},   
    // data() { return {
    //     userName: store.state.user.name
    // }},
    computed: {
        seedServer():string { return i18n.global.t('seedServer'); },
        seedPlayer1():string { return i18n.global.t('seedPlayer1'); },
        seedPlayer2():string { return i18n.global.t('seedPlayer2'); },
        result():string { return i18n.global.t('result'); },
        whatIsFairPlay():string { return i18n.global.t('whatIsFairPlay'); },
        sha512text():string { return i18n.global.t('sha512text'); },
        hfpTitle():string { return i18n.global.t('round'); },
        fairPlayData():object { 
            // console.log('fairPlayData  = ',store.state.fairPlayData) 
            return store.state.fairPlayData },
    },
    methods: {

       
        clickClose():void {
            store.commit('M_IS_MYBET_FP_OPENED', false);
        },
        openFirePlay():void {
            store.commit('M_IS_MYBET_FP_OPENED', false);
            store.commit('M_CHANGE_TAB', Tabs.FairPlay);
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Expand);
        },
        copy():void {
            // let str: any = store.state.fairPlayData.resultHex.toString() + store.state.fairPlayData.resultDecimal+ store.state.fairPlayData.resultValue
             let str: any = store.state.fairPlayData.combySeed
            navigator.clipboard.writeText(str)
                .then(() => {                  
                })
                .catch(err => {                  
                });
            },
    }

});


