import store from '../store';
import Config from "../Config";
import BaseRequest from "./BaseRequest";
import RequestChat from "./RequestChat";

export default class Net {
    public static instance:Net;
    private socket!:WebSocket;
    private requests!:Map<string, BaseRequest>;
    private onConnectedCallback!:()=>void;
    private notConnected!:()=>void;
    private timerCheckPing:any;

    constructor(onConnected:()=>void, notConnected:()=>void) {
        let self = this
        if(Net.instance != null) return;
        Net.instance = this;
        this.onConnectedCallback = onConnected;
        this.notConnected = notConnected;
        this.requests = new Map<string, BaseRequest>();
        var protocol = Config.IS_SEQURITY_WS ? "wss" : "ws";
        this.socket = new WebSocket(protocol + "://" + Config.HOST + ":" + Config.PORT);
        this.socket.onopen = (event)=> this.onOpen(event);
        this.socket.onmessage = (event)=> this.onMessage(event);
        this.socket.onerror = (event)=> this.onError(event);
        this.socket.onclose = (event)=> this.onClose(event);
        this.timerCheckPing = 1        
    }

    public static sendRequest(request:BaseRequest, data:any) {    
        let t = Net.instance;
        if(!t.requests.has(request.msgId)) t.requests.set(request.msgId, request);
        t.socket.send(JSON.stringify(data));
    }   

    public static sendPing() {  
        let t = Net.instance; 
        let timerId = setTimeout(function request() {
            t.socket.send("ping");
            // Config.IS_LOG_SOCET_SHOWING && console.log('ping');            
            timerId = setTimeout(request, 5000);
        }, 5000); 
    }


    public wsClosed(){
        clearTimeout(this.timerCheckPing)
        this.socket.close()       
    }

    public static sendRequestСhat(request:RequestChat, data:any) {    
        let t = Net.instance;        
        t.socket.send(JSON.stringify(data));
    }


    /////////////////////////////// private ///////////////////////////////
    
    private onOpen(event:Event) {
        this.onConnectedCallback();
    }
    private onMessage(event:MessageEvent):any {   
        // Config.IS_LOG_SOCET_SHOWING && console.log("pong");
        if(this.timerCheckPing){ 
            clearTimeout(this.timerCheckPing)
            this.timerCheckPing = setTimeout(()=>{store.state.alertMessage.action = 'reload', store.commit("M_DEFAULTSOCKETCLOSE", true), Config.IS_LOG_SOCET_SHOWING && console.error('_*_*_*_*_*_*_*_*_*_*_*_*_*The socket closed after 15 seconds*_*_*_*_*_*_*_*_*_*_*_*_*_*_'), this.socket.close(1000, "The socket closed after 15 seconds");}, 15000);
        }
        this.timerCheckPing         
        let data:any;
        try {
           data = JSON.parse(event.data)
        } catch (error) {
            Config.IS_LOG_SOCET_SHOWING && console.error(error);
        }
        if(data) {
            if(data.patch) this.parsePatch(data.patch);
            else if(data.error) {Config.IS_LOG_SOCET_SHOWING && console.warn("SERVER: " + data.error), this.parseData(data)}
            else this.parseData(data);
        }      
    }
    private onError(event:Event) {
        Config.IS_LOG_SOCET_SHOWING && console.log("Net: socket error");
        this.notConnected();
        store.commit("M_DEFAULTSOCKETCLOSE", true);
        Config.IS_LOG_SOCET_SHOWING && console.error("_socket onError ", event)
        store.state.alertMessage.action = 'reload'
    }

    private onClose(event:CloseEvent) {
        this.wsClosed()
        Config.IS_LOG_SOCET_SHOWING && console.log("Net: socket closed");
        this.notConnected();
        store.commit("M_DEFAULTSOCKETCLOSE", true);
        Config.IS_LOG_SOCET_SHOWING && console.error("_socket onClose", event)
        store.state.alertMessage.action = 'reload'
    }
    
    private parseData(dataData:any) {  
        if(dataData.action == "alertMessage"){
            store.commit("M_ALERTMESSAGEY_TOTAL_DATA",dataData)
        }
    
        // if(dataData.error){
        //     store.commit("M_ALERTMESSAGEY_TOTAL_DATA",{action:"reload",type:dataData.error.code})
        // }
        if(dataData.id && this.requests.has(dataData.id)) {  
            console.log('---111----',dataData);
                     
            let callBack = this.requests.get(dataData.id)?.onReceived; 
            try {
                if(callBack) callBack(dataData);
            }
            catch(e) {
                // store.commit("M_DEFAULTSOCKETCLOSE",true)
                Config.IS_LOG_SOCET_SHOWING && console.error("Server Error data see parseData", e)
                // store.state.alertMessage.action = 'reload'
                // TODO show large 
            }
            finally {
                this.requests.delete(dataData.id);
            }            
        }
    }
    private parsePatch(patchData:any) {       
        try {
            store.commit("M_CHANGE_BY_PATCH", patchData);
        }
        catch(e) {
            // store.commit("M_DEFAULTSOCKETCLOSE",true)
            // store.state.alertMessage.action = 'reload'
            Config.IS_LOG_SOCET_SHOWING && console.log("-------------------- 4 ", e)
            // TODO show large alert
        }
        
    }
    


}