
import { defineComponent } from 'vue'; 
import PanelJackpot from './components/PanelJackpot.vue';
import PanelGame from './components/PanelGame.vue';
import PanelBet from './components/PanelBet.vue';
import Footer from './components/Footer.vue';
import RoomChooser from './components/RoomChooser.vue';
import FpCheckBet from './components/FpCheckBet.vue';
import TableBlock from './components/TableBlock.vue';
import GameFieldCmp from './components/GameFieldCmp.vue';
import Settings from './components/Settings.vue';
import ChangeName from './components/tables/ChangeName.vue';
import ChangeAvatar from './components/tables/ChangeAvatar.vue';
import Changelanguage from './components/tables/Changelanguage.vue';
import SocketClosed from './components/tables/SocketClosed.vue';
import store from './store';
import {LayoutMode} from './store';
import { ref } from 'vue';
import { useSound } from '@vueuse/sound';
import btnPushSfx from './assets/audio/razlojka.mp3';
import AlertG from './components/AlertG.vue';
import backgroundSfx from './assets/audio/background.mp3';
import { Tabs } from './store';

// import background from './assets/audio/background.mp3';
// from(require("../assets/gameField/pointer.svg"));


export default defineComponent({
    name: 'Chat',
    components: {
        PanelJackpot, PanelGame, PanelBet, Footer, RoomChooser, TableBlock, GameFieldCmp, Settings, ChangeName, ChangeAvatar, SocketClosed, FpCheckBet, Changelanguage, AlertG
    },
    setup() {   
        const {sound} = useSound(backgroundSfx, {volume: 0.5,interrupt: false});     
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush,sound};
    },
    computed: {
        soundStart(){
            let audioObj = new Audio(require('./assets/audio/background.mp3'));          
            const volume = ref(0.1)
            const { play } = useSound(require('./assets/audio/background.mp3'), {
            volume,
            interrupt: true,
            })
        },
        isExpanded():boolean {
            return store.state.layoutMode == LayoutMode.Expand;
        },
        isPopupOpened():boolean {        
           
            return store.state.isPopupOpened;
        }, 
        isMyBetFpOpened():boolean {
            return store.state.isMyBetFpOpened;
        }, 
        isOpenChName():boolean { return store.state.isOpenChangeName; }, 
        isMaxBet():boolean { return store.state.isMaxBet; }, 
        isOpenAvatar():boolean { return store.state.isOpenChangeAvatar;}, 
        isOpenChangelanguage():boolean { return store.state.isOpenChangelanguage;},   
        isSocketClosed():boolean { return store.state.isSocketClosed || store.state.isdefaultSocketClose || store.state.isInsufficientBalance || store.state.isSessionOverride || store.state.alertMessage.isAlertMessage},   
    },
    mounted(){
    //   let t = Settings.$refs
      // @ts-ignored
    //   console.log('----------------------------------------t',this.$refs)
    //   store.state.isPopupOpened= false
    //   store.commit('M_CHANGE_LANGUAGE_POPUP_IS_OPENED', false);
    //   store.commit('M_CHANGE_NAME_POPUP_IS_OPENED', false);
    //   store.commit('M_CHANGE_ICON_POPUP_IS_OPENED', false);         
    },
    beforeMount(){
    //   store.state.isPopupOpened= true
    //   store.commit('M_CHANGE_LANGUAGE_POPUP_IS_OPENED', true);
    //   store.commit('M_CHANGE_NAME_POPUP_IS_OPENED', true);
    //   store.commit('M_CHANGE_ICON_POPUP_IS_OPENED', true);
    //   store.commit('M_POPUP_IS_OPENED', true);
    //   store.commit('M_CHANGE_TAB', Tabs.AllBets);
    },
    methods: {
        closeRoomsAndMusicStart(){            
            this.sound.loop(true)
            this.sound.volume(0.5),
            // console.log(store.state.optionMusic);                  
            store.state.optionMusic &&!this.sound.playing()? (this.sound.play(), store.commit('M_OPTION_MUSIC', true)): null;  
            store.dispatch('HIDE_ROOM_LIST');
        }
        
    }
})
