{
  "betDiapason": "賭注 / 您的賭池",
  "yourBalance":  "您的餘額",
  "min": "最低",
  "max": "最高",
  "auto": "自動",
  "using": "游戲次數",
  "roomAmount": "房間數量",
  "rooms": "房間",
  "room": "房間",
  "memberAmount": "參與者人數",
  "betLabelName": "名字",
  "betLabelTime": "時間",
  "Ok": "Ok",
  "betsAmount": "獎金數額",
   
  "allBets": "所有投注",
  "myBets": "我的投注",
  "history": "歷史",
  "chat": "聊天",
  "bet": "投注",
  "сhance": "機會",
  "win": "獎金",
  "amountOnline": "在綫人數",
  "chatPrompt": "文字",
  "send": "發送",
  "settings": "設定",
  "changeAvatar": "更改頭像",
  "changeName": "更改名字",
  "sound": "聲音",
  "music": "音樂",
  "animations": "動畫",
  "bonusBet": "賭注紅利",
  "language": "語言",
  "toHome": "主頁",
  "gettingBets": "下注",
  "cancel": "取消",
  "inroom": "您現在在房間：",
  "data": "日期",
  "checkText": "此名稱不可用，請重試",
  "inputname": "輸入您的名字",
  "nameTaken": "該用戶名已被佔用，請嘗試另一個用户名",
  "nameInvalid": "錯誤。您可使用 az，0-9 和 _。字串長度 - 從 4 到 16",
  "wrongDiapason": "錯誤。\n您在當前開獎中的總投注金額已超過",
  "default": "連接已斷開连接\n请重載遊戲。",
  "InsufficientFunds": "您的賬戶内沒有錢。\n請為您的帳戶注資以繼續。",
  "SessionOverride": "很可惜，我們的游戲\n不支持多個游戲環節",
  "shutdownNotification": "系統維保中。抱歉，我們會盡快進行系統維護。請完成您當前的遊戲並退出遊戲。在維護期間，我們的游戲將會在一段時間内無法使用，我們會在維護結束后回來繼續爲您帶來愉悅的體驗！",
  "incorrectGIN": "此游戲目前無法使用。",
  "maintenance": "系統維護中。抱歉，我們目前正在進行系統維護。我們很快回來繼續爲您帶來愉悅的游戲體驗。謝謝",
  "sessionTimeout": "您的游戲因不活躍已超時",
  "ipBlocked": "由於當地法規，此游戲無法在您所在的國家/地区使用。",
  "notAvailable": "Not available"}