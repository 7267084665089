
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store'
import MyBetItem from './MyBetItem.vue';

export default defineComponent({
    name: 'MyBets',
    components: {
        MyBetItem
    },
    computed: {
        myBets() {
            return store.state.myBets;
        },
        betLabel():string {
            return i18n.global.t('bet');
        },
        сhanceLabel():string {
            return i18n.global.t('сhance');
        },
        winLabel():string {
            return i18n.global.t('win');
        },
        winFairPlay():string {
            return i18n.global.t('winFairPlay');
        },
        betLabelTime():string {
            return i18n.global.t('betLabelTime');
        },
    }
});


