{
  "betDiapason": "베팅 / 귀하의 풀",
  "yourBalance": "내 잔액",
  "min": "분",
  "max": "최대",
  "auto": "자동",
  "using": "게임 사용",
  "roomAmount": "방 수",
  "rooms": "방",
  "room": "방",
  "memberAmount": "참가자 수",
  "betLabelName": "이름",
  "betLabelTime": "시간",
  "Ok": "확인",
  "betsAmount": "승리 금액",
   
  "allBets": "모든 베팅",
  "myBets": "내 베팅",
  "history": "기록",
  "chat": "채팅",
  "bet": "베팅",
  "сhance": "확률",
  "win": "승리",
  "amountOnline": "온라인 번호",
  "chatPrompt": "텍스트",
  "send": "보내기",
  "settings": "설정",
  "changeAvatar": "아바타 변경",
  "changeName": "이름 변경",
  "sound": "사운드",
  "music": "음악",
  "animations": "애니메이션",
  "bonusBet": "보너스 베팅",
  "language": "언어",
  "toHome": "홈",
  "gettingBets": "베팅하기",
  "cancel": "취소",
  "inroom": "내가 들어온 방:",
  "data": "날짜",
  "checkText": "사용할 수 없는 이름입니다. 다시 시도해 주십시오.",
  "inputname": "이름을 입력하세요",
  "nameTaken": "이 사용자 이름은 이미 사용 중입니다. 다른 사용자 이름을 시도하십시오.",
  "nameInvalid": "오류. A-z의 알파벳과, 0-9 및 _를 사용할 수 있습니다. 문자 길이 는 4 - 16자입니다",
  "wrongDiapason": "오류. \n 현재 추첨의 총 베팅 금액이 초과되었습니다.",
  "default": "접속 끊김 \n 게임을 새로고침하세요.",
  "InsufficientFunds": "계정에 잔액이 없습니다. \n 계속하려면 계정에 잔액을 입금하세요.",
  "SessionOverride": "안타깝지만 다중 세션은 \n   본 게임에서 지원되지 않습니다",
  "shutdownNotification": "시스템 점검 중 죄송합니다. 최대한 빨리 점검을 완료하겠습니다. 현재 게임 세션을 종료하고 게임을 나가십시오. 점검이 진행되는 동안 게임을 잠시 사용할 수 없습니다. 잠시 후 다시 찾아뵙겠습니다!",
  "incorrectGIN": "현재 게임을 이용할 수 없습니다.",
  "maintenance": "시스템 점검 중입니다. 죄송합니다. 현재 시스템 점검이 진행 중입니다. 잠시 후 다시 여러분께 즐거움을 드릴 수 있도록 하겠습니다. 감사합니다",
  "sessionTimeout": "활동이 없어 세션이 종료되었습니다.",
  "ipBlocked": "현지 법률로 인해 본 게임을 귀하의 국가에서 이용할 수 없습니다.",
  "notAvailable": "Not available"}