{
  "betDiapason": "Puntata / il tuo pool",
  "yourBalance": "Il vostro saldo",
  "min": "min",
  "max": "max",
  "auto": "auto",
  "using": "Il gioco usa",
  "roomAmount": "Numero di stanze",
  "rooms": "Stanze",
  "room": "Stanza",
  "memberAmount": "Numero di partecipanti",
  "betLabelName": "Nome",
  "betLabelTime": "Ora",
  "Ok": "Ok",
  "betsAmount": "IMPORTO VINCITA",
   
  "allBets": "TUTTE LE SCOMMESSE",
  "myBets": "LE MIE SCOMMESSE",
  "history": "STORIA",
  "chat": "CHAT",
  "bet": "Scommessa",
  "сhance": "Possibilità",
  "win": "Vincita",
  "amountOnline": "Quantità online",
  "chatPrompt": "Messaggio di testo",
  "send": "Inviare",
  "settings": "Impostazioni",
  "changeAvatar": "Cambiare avatar",
  "changeName": "Cambiare nome",
  "sound": "Suono",
  "music": "Musica",
  "animations": "Animazioni",
  "bonusBet": "Puntata bonus",
  "language": "Lingua",
  "toHome": "All'home",
  "gettingBets": "RICEZIONE SCOMMESSE",
  "cancel": "Annullare",
  "inroom": "Siete nella stanza:",
  "data": "Data",
  "checkText": "Il nome non è disponibile, riprovate",
  "inputname": "Inserite il vostro nome",
  "nameTaken": "Questo nome è già occupato, sceglietene un altro",
  "nameInvalid": "Errore Potete utilizzare az, 0-9 e _. Lunghezza dei simboli da 4 a 16",
  "wrongDiapason": "Errore \n Il massimo di scommesse nell'estrazione corrente è stato superato",
  "default": "Connessione persa \n Per favore, riavviate",
  "InsufficientFunds": "Sul conto non ci sono soldi \n Per favore, ricaricate per continuare",
  "SessionOverride": "Purtroppo sono state avviate più sessioni correttamente \n   Non disponibile tra i giochi",
  "shutdownNotification": "Manutenzione del sistema Ci scusiamo, a breve effettueremo la manutenzione del sistema. Termina la sessione di gioco corrente ed esci dal gioco. Durante i lavori di manutenzione, i giochi non saranno disponibili per un po' di tempo. Saremo lieti di rivederti dopo aver completato la manutenzione!",
  "incorrectGIN": "Attualmente il gioco non è disponibile",
  "maintenance": "Manutenzione tecnica del sistema Siamo spiacenti, al momento stiamo eseguendo la manutenzione del sistema. Saremo lieti di rivederti dopo aver completato la manutenzione. Grazie",
  "sessionTimeout": "La tua sessione è scaduta per inattività",
  "ipBlocked": "A causa delle leggi locali, questo gioco non è disponibile nel tuo paese.",
  "notAvailable": "Not available"}