{
  "betDiapason": "Bet / Your pool",
  "yourBalance": "Your balance",
  "noWin": "no win",
  "winner": "winner",
  "min": "min",
  "max": "max",
  "auto": "auto",
  "using": "Game uses",
  "roomAmount": "Number of rooms",
  "rooms": "Rooms",
  "room": "Room",
  "memberAmount": "Number of participants",
  "betsAmount": "WIN AMOUNT",
  "allBets": "ALL BETS",
  "myBets": "MY BETS",
  "history": "HISTORY",
  "chat": "CHAT",
  "bet": "Bet",
  "сhance": "Chance",
  "win": "Win",
  "betLabelTime": "Time",
  "betLabelName":"Name",
  "winFairPlay":"Fair Play",
  "amountOnline": "Number online",
  "chatPrompt": "Text",
  "send": "Send",
  "settings": "Settings",
  "changeAvatar": "Change avatar",
  "changeName": "Change name",
  "sound": "Sound",
  "music": "Music",
  "animations": "Animations",
  "bonusBet": "Bonus Bet",
  "language": "Language",
  "toHome": "Home",
  "gettingBets": "GETTING BETS",
  "cancel": "Cancel",
  "inroom": "You are in the room:",
  "data": "Date",
  "hashTitle": "Current draw :",


      
  "seedServer":"COLLECTION OF INITIAL DATA SERVER",
  "seedPlayer1":"PLAYER 1 COLLECTION OF INITIAL DATA",
  "seedPlayer2":"PLAYER 2 COLLECTION OF INITIAL DATA",
  "sha512text":"HASHED IN 512",

  "whatIsFairPlay":"What is",


  "checkText": "This name is unavailable, please try again",
  "inputname": "Enter your name",
  "nameTaken": "This username is already taken, please try another one",
  "nameInvalid": "Error. You can use az, 0-9 and _. Character length - from 4 to 16",
  "wrongDiapason": "Error. \n The total amount of your bets in the current drawing has been exceeded",
  "default": "Connection lost \n Please reload the game",
  "InsufficientFunds": "There is no money in your account.\n Please fund your account to continue",
  "SessionOverride": "Unfortunately multisessions are \n   not supported in our games",
  "shutdownNotification": "System maintenance Sorry, we will run system maintenance as soon as possible. Please finish your current game session and exit the game. During maintenance, our games will be unavailable for some time, after that we will back to delight you again!",
  "incorrectGIN": "The game is currently unavailable",
  "maintenance": "System maintenance. Sorry, we're running a system maintance currently. We will be back to delight you with our games soon. Thanks",
  "sessionTimeout": "Your session has timed out due to inactivity",
  "ipBlocked": "Due to local legislation, this game is not available in your country",
  "notAvailable": "Not available"}