
import { defineComponent } from 'vue';
import { Tabs } from '../store'
import store from '../store'
import i18n from '../i18n';
import AllBets from './tables/AllBets.vue';
import MyBets from './tables/MyBets.vue';
import History from './tables/History.vue';
import Chat from './tables/Chat.vue';
import TextInfo from './tables/TextInfo.vue';
import {LayoutMode} from '../store';
import Utils from '@/Utils';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'TableBlock',
    props: {},
    components: {
        AllBets, MyBets, History, Chat, TextInfo
    },
    mounted() {
        window.onresize = ()=> {            
            Utils.isLandscape() && store.commit('M_CHANGE_LAYOUT', LayoutMode.Normal);
        },
        window.addEventListener('orientationchange',()=>{
            this.selectTab('allBets');
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Normal);
        })
    },
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    computed: {
        // winner() { return store.state.room.game.winner },  
        betsAmountLabel():string { return i18n.global.t('betsAmount'); },
        betsAmount():number { return store.state.room.game.bets.length; },
        totalbetsAmount():string { 
            let sum = store.state.room?.game?.winner?.win ? store.state.room.game.winner.win:(
            store.state.room.game.bets.filter(bet => !bet.fail).reduce((sum, bet) => sum + bet.amount, 0)
            )
            // let sum = 0    
            // store.state.room.game.bets.forEach((item)=>{
            // sum += item.amount;
            // })    
            // let sum = store.state.room.game.bets.reduce((sum, bet) => sum + bet.amount, 0);
            
        return Utils.centsToMoney(sum) + " " + store.state.user.currency.code
        },
        allBetsLabel():string { return i18n.global.t('allBets'); },
        myBetsLabel():string { return i18n.global.t('myBets'); },
        historyLabel():string { return i18n.global.t('history'); },
        chatLabel():string { return i18n.global.t('chat'); },

        isAllBets() { return store.state.tab == Tabs.AllBets },
        isMyBets() { return store.state.tab == Tabs.MyBets },
        isHistory() { return store.state.tab == Tabs.History },
        isChat() { return store.state.tab == Tabs.Chat },
        chatAmount() { return store.state.chat.messages.length; },

        currentTabComponent():string {
            switch(store.state.tab) {
                case Tabs.MyBets: return "MyBets";
                case Tabs.History: return "History";
                case Tabs.Chat: return "Chat";
                case Tabs.Info: return "TextInfo";
                case Tabs.FairPlay: return "TextInfo";
                default: return "AllBets";
            }
        },

        isExpandedInfo():boolean {
            return store.state.layoutMode == LayoutMode.Expand &&
                (store.state.tab == Tabs.Info || store.state.tab == Tabs.FairPlay);
        },
        isExpandedTable():boolean {
            return store.state.layoutMode == LayoutMode.Expand &&
                store.state.tab != Tabs.Info && store.state.tab != Tabs.FairPlay;
        }
    },   
    // watch:{
    //     'winner'(){
    //       this.totalbetsAmount
    //     }

    // },
    methods: {       
        selectTab(tabId:string):void {
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_TAB', tabId);
            store.dispatch('CHANGE_TAB', tabId);
            if(Utils.isPortrait()) {
                store.commit('M_CHANGE_LAYOUT', LayoutMode.Expand);
            }
        },
        clickClose():void {
            this.selectTab('allBets');
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Normal);
        },
        clickBetAmount():void {
            this.selectTab('allBets');
        }
    }
});


