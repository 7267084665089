
import { defineComponent } from 'vue';
import i18n from '../i18n';
import store, { Room, Stage } from '../store';
import GameField from '../gameField/GameField'
import { BetVO } from '@/types';
import Config from '@/Config';
import GameController from '../gameField/GameController';
import { useSound } from '@vueuse/sound';
import razlojkaSfx from '../assets/audio/razlojka.mp3';
import vrascheniye_barabanaSfx from '../assets/audio/vrascheniye_barabana.mp3';
import nachaloSfx from '../assets/audio/nachalo.mp3';
import winnerSfx from '../assets/audio/winner.mp3';
// import backgroundSfx from '../assets/audio/background.mp3';
import Utils from '@/Utils';


let gameCtrl = new GameController();

export default defineComponent({
    name: 'GameFieldCmp',
    
    // props: ["soundBack"],  
   
    data() {
        return {
            userWinner: "",
            userWinnerLabel: "",
            userWinValue: "",
            userCurrency: ""
        }
    },

    computed: {         
        timerLabel():string {
            return i18n.global.t('gettingBets');
        },
        isTimerActive():boolean {
            return store.state.room.game.stage == Stage.Trade;
        },
        isLogoDisable():boolean {
            return store.state.room.type != Room.Demo;
        },
        isWinnerActive():boolean {
            return store.state.room.game.stage == Stage.Award;
        },
        timerValue():string {
            if(store.state.room.game.stage == Stage.Trade) {
                let totalSec = store.state.timeRoom /1000;
                if(totalSec < 0) totalSec = 0;
                let m = Math.floor(totalSec/60);
                let s = Math.round(totalSec % 60);
                return (m < 10 ? "0" : "") + m + ":" + (s < 10 ? "0" : "") + s;
            } else return "";
        },
        currentStage(context):any {         
            let t = store.state.room.game.stage; 
            let b = store.state.room.game.bets.slice();
            return {b, t, context}
        },
        soundMuted(context):any {     
            if(!store.state.optionSound){
                    context.winner?.stop()
                    context.vrascheniye_barabana?.stop()
                    context.nachalo?.stop() 
            }   
            return {context}
        },
        requestToRoom(context):string {
            return store.state.requestToRoom;
        }
    },

    setup() {       
        const razlojka = useSound(razlojkaSfx, {volume: 1.0,interrupt: false}).sound;
        const winner = useSound(winnerSfx, {volume: 0.7, interrupt: false}).sound;
        const vrascheniye_barabana = useSound(vrascheniye_barabanaSfx, {volume: 1.0,interrupt: false}).sound;
        const nachalo = useSound(nachaloSfx, {volume: 0.7,interrupt: false}).sound;
        // const background = useSound(backgroundSfx, {volume: 1.0,interrupt: false}).sound;
        return {razlojka, vrascheniye_barabana, nachalo, winner}; //, background
    },

    methods: {
        fillWinnerText(isClear = false) {
            if(isClear) {
                this.userWinnerLabel = this.userWinner = this.userCurrency = this.userWinValue = "";
                return;
            }
            const winner = store.state.room.game.winner; 
            const isWin = winner && winner.name.substring(0, Config.PLAYER_NAME_MAX_LENGTH) 
            this.userWinnerLabel = i18n.global.t(isWin ? 'winner' : 'noWin').toUpperCase();
            this.userWinner = winner ? winner.name : "";
            this.userCurrency = winner && winner.win ? store.state.user.currency.code : "";
            this.userWinValue = winner && winner.win ? Utils.roundMoney(winner.win) : "";  
        }
    },

    watch: {
        soundMuted(context){},

        requestToRoom(context) {
            Config.IS_LOG_STATE && console.log("##################################### Enter to room  #########################################################");
            gameCtrl.clear();

        },

        currentStage: (t)=> {  
            let toStage  = t.t 
            let self = t.context
            let bets = t.b          
            // GameField.instance.setAddedBets(gameCtrl.fillBets())
            // GameField.instance.setCopyAddedBets(gameCtrl.fillBets())
            


            Config.IS_LOG_STATE && console.log(`-------------------------------------------------STAGE: ${toStage}----------------------------------------------------`);          
            function stopSound(){                  
                self.winner?.stop();
                self.vrascheniye_barabana?.stop();
                self.nachalo?.stop();
            } 
            switch(toStage) {
                case Stage.Idle:        
                    store.state.totalBetInRound = 0            
                    GameField.instance.clearBets();
                    GameField.instance.radarOn();
                    break;

                case Stage.Trade:
                    gameCtrl.doTrade();
                    stopSound();
                    self.fillWinnerText(true);
                    break;

                case Stage.Preparation:
                    stopSound()
                    self.nachalo && (self.nachalo._volume = 0.5);
                    store.state.optionSound &&  self.nachalo?.play();
                    break;

                case Stage.Landing:
                    gameCtrl.doLanding(store.state.room.game.time.left);
                    break;

                case Stage.Roll:
                    stopSound();
                    // GameField.instance.instantDrawSectors_RecoveryState(false)  //##3
                    store.state.optionSound && self.vrascheniye_barabana?.play()
                    gameCtrl.doRoll(store.state.room.game.time.left);
                    break;
                    
                case Stage.Award: 
                    // GameField.instance.dickStopAndClear() //##3  
                    // GameField.instance.instantDrawSectors_RecoveryState(false)  //##3 
                    self.fillWinnerText();
                    gameCtrl.doAward();  
                    stopSound()    
                    store.state.optionSound && self.winner?.play();
                    // GameField.instance.dickStopAndClear() //##3  
                    // GameField.instance.instantDrawSectors_RecoveryState(false)  //##3 
                    break;

                default:
                    Config.IS_LOG_STATE && console.warn(`unknown stage: ${toStage}`);
                   
            }
            GameField.instance.ticker.update();
        }
    }
});




