{
  "betDiapason": "Apuesta / Tu puesta del ganador",
  "yourBalance": "Su saldo",
  "min": "mín",
  "max": "máx",
  "auto": "auto",
  "using": "El juego usa",
  "roomAmount": "Número de salas",
  "betLabelName": "Nombre",
  "betLabelTime": "Tiempo",
  "Ok": "Ok",
  "betsAmount": "CANTIDAD DE GANANCIA",
  "rooms": "Salas",
  "room": "Sala",
  "memberAmount": "Número de participantes",
  "allBets": "TODAS LAS APUESTAS",
  "myBets": "MIS APUESTAS",
  "history": "HISTORIAL",
  "chat": "CHAT",
  "bet": "Apuesta",
  "сhance": "Probabilidad",
  "win": "Ganancia",
  "amountOnline": "Cantidad online",
  "chatPrompt": "Mensaje de texto",
  "send": "Enviar",
  "settings": "Configuraciones",
  "changeAvatar": "Cambiar avatar",
  "changeName": "Cambiar nombre",
  "sound": "Sonido",
  "music": "Música",
  "animations": "Animaciones",
  "bonusBet": "Apuesta de bonus",
  "language": "Idioma",
  "toHome": "A la página principal",
  "gettingBets": "RECEPCIÓN DE APUESTAS",
  "cancel": "Cancelación",
  "inroom": "Usted está en la sala:",
  "data": "Fecha",
  "checkText": "Este nombre no está disponible, inténtelo de nuevo.",
  "inputname": "Introduzca su nombre",
  "nameTaken": "Este nombre de usuario ya existe, intente con otro",
  "nameInvalid": "Error. Puede utilizar az, 0-9 y _. Longitud de caracteres: de 4 a 16",
  "wrongDiapason": "Error. \n Se superó el monto total de Sus apuestas en el sorteo actual",
  "default": "Conexión perdida \n Reinicie el juego por favor.",
  "InsufficientFunds": "No hay dinero en su cuenta. \n Por favor recargue su cuenta para continuar.",
  "SessionOverride": "Desafortunadamente, la opcion de ejecutar varias sesiones al mismo tiempo \n   no está disponible en nuestros juegos.",
  "shutdownNotification": "Mantenimiento del sistema, Le pedimos disculpas, empezamos el mantenimiento del sistema en breve. Finalice la sesión de juego actual y salga del juego. Durante el trabajo de mantenimiento, los juegos no estarán disponibles durante algún tiempo. Estaremos encantados de volver a verlo después de finalizar el mantenimiento!",
  "incorrectGIN": "El juego no está disponible actualmente.",
  "maintenance": "Mantenimiento del sistema. Lo sentimos, actualmente estamos realizando tareas de mantenimiento en el sistema. Estaremos encantados de volver a verlo después de finalizar el mantenimiento. Gracias",
  "sessionTimeout": "Su sesión ha caducado por inactividad",
  "ipBlocked": "Debido a las leyes locales, este juego no está disponible en su país.",
  "notAvailable": "Not available"}