{
  "betDiapason": "เดิมพัน / เงินเดิมพัน ของคุณ",
  "yourBalance": "ยอดเงินของคุณ",
  "min": "น้อยสุด",
  "max": "มากสุด",
  "auto": "อัตโนมัติ",
  "using": "เกมใช้",
  "roomAmount": "จำนวนห้อง",
  "rooms": "ห้อง",
  "room": "ห้อง",
  "memberAmount": "จำนวนผู้เข้าร่วม",
  "betLabelName": "ชื่อ",
  "betLabelTime": "เวลา",
  "Ok": "ตกลง ",
  "betsAmount": "จำนวนเงินที่ชนะ",
  
  "allBets": "เดิมพันทั้งหมด",
  "myBets": "เดิมพันของฉัน",
  "history": "ประวัติ",
  "chat": "ห้องสนทนา",
  "bet": "เดิมพัน",
  "сhance": "ความน่าจะเป็น",
  "win": "ชัยชนะ",
  "amountOnline": "จำนวนออนไลน์",
  "chatPrompt": "ข้อความ",
  "send": "ส่ง",
  "settings": "การตั้งค่า",
  "changeAvatar": "เปลี่ยนรูปประจำตัว",
  "changeName": "เปลี่ยนชื่อ",
  "sound": "เสียง",
  "music": "ดนตรี",
  "animations": "แอนิเมชั่น",
  "bonusBet": "เดิมพันโบนัส",
  "language": "ภาษา",
  "toHome": "ไปยังหน้าหลัก",
  "gettingBets": "อัตราการรับ",
  "cancel": "ยกเลิก",
  "inroom": "คุณอยู่ในห้อง:",
  "data": "วันที่",
  "checkText": "ไม่มีชื่อนี้ โปรดลองอีกครั้ง",
  "inputname": "ใส่ชื่อของคุณ",
  "nameTaken": "ชื่อผู้ใช้นี้ไม่ว่างลองอีกครั้ง",
  "nameInvalid": "ข้อผิดพลาด  คุณสามารถใช้ A-Z, 0-9 และ _  ความยาวสัญลักษณ์ - จาก 4 ถึง 16",
  "wrongDiapason": "ข้อผิดพลาด  \n จำนวนเงินเดิมพันทั้งหมดของคุณในงวดปัจจุบันเกินแล้ว",
  "default": "การเชื่อมต่อหายไป \n โปรดรีบูตเกม ",
  "InsufficientFunds": "บัญชีของคุณไม่มีเงิน  \n กรุณา เติมเต็มบัญชีของคุณ เพื่อดำเนินการต่อ ",
  "SessionOverride": "ขออภัย การเปิดตัวพร้อมกันหลายเซสชัน  \n   ในเกมของเราไม่พร้อมใช้งาน ",
  "shutdownNotification": "ขออภัยระบบกำลังปรับปรุง เราจะจัดการปรับปรุงระบบในไม่ช้า  ทำเซสชันเกมปัจจุบันให้สมบูรณ์และออกจากเกม  ขณะที่ระบบกำลังปรับปรุงเกมจะไม่สามารถใช้งานได้  เรายินดีที่ได้พบคุณอีกครั้งหลังจากเสร็จสิ้นการให้บริการ!",
  "incorrectGIN": "ในขณะนี้เกมไม่สามารถใช้งานได้ ",
  "maintenance": "การปรับปรุงระบบ  ขออภัย ในขณะนี้เราดำเนินการปรับปรุงระบบ  เรายินดีที่ได้พบคุณอีกครั้ง หลังจากบริการเสร็จสมบูรณ์  ขอบคุณ",
  "sessionTimeout": "เซสชั่นของคุณหมดอายุเนื่องจากขาดกิจกรรม",
  "ipBlocked": "สอดคล้องกับกฎหมายท้องถิ่นเกมนี้ไม่สามารถใช้ได้ในประเทศของคุณ ",
  "notAvailable": "Not available"}