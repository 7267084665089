{
  "betDiapason": "賭け/あなたのプール",
  "yourBalance": "あなたのバランス",
  "min": "最低",
  "max": "最大",
  "auto": "自動的に",
  "using": "ゲームでは使用される",
  "roomAmount": "ルームの数",
  "rooms": "ルーム",
  "room": "ルーム",
  "memberAmount": "参加者の数",
  "betLabelName": "名前",
  "betLabelTime": "時間",
  "Ok": "オケ",
  "betsAmount": "勝利額",
   
  "allBets": "全て賭けの数",
  "myBets": "私の賭け",
  "history": "ヒストリー",
  "chat": "チャット",
  "bet": "賭け",
  "сhance": "確率",
  "win": "賞金",
  "amountOnline": "オンラインの数",
  "chatPrompt": "メッセージテキスト",
  "send": "送信する",
  "settings": "設定",
  "changeAvatar": "アバターを変更する",
  "changeName": "名前を変更する",
  "sound": "音",
  "music": "音楽",
  "animations": "アニメーション",
  "bonusBet": "ボーナス賭け",
  "language": "言語",
  "toHome": "ホーム",
  "gettingBets": "賭けの受入",
  "cancel": "キャンセルする",
  "inroom": "あなたはルームにいます：",
  "data": "日付",
  "checkText": "この名前は利用できません。もう一度お試しください",
  "inputname": "自分の名前を入力してください",
  "nameTaken": "このユーザー名はすでに使用されています。別のユーザー名を試してください",
  "nameInvalid": "エラー。A-z、0-9、および_を使用することができます。文字の長さ-4から16",
  "wrongDiapason": "エラー。\n現在の抽選での賭けの合計額を超えました",
  "default": "接続が切れました\nゲームを再開してください。",
  "InsufficientFunds": "アカウントにお金がありません。\n続行するには、アカウントに入金してください。",
  "SessionOverride": "残念ながら、同時に複数のセッションを実行することが\n私たちのゲームでは利用できません。",
  "shutdownNotification": "システムのメンテナンスが行わっています。すみません。近い将来に、システムのメンテナンスを開始します。現在のゲームのセッションを終了し、ゲームから出てください。メンテナンスの作業中、ゲームはしばらくの間利用できなくなります。メンテナンス終了後、またお会いできることを嬉しく思います！",
  "incorrectGIN": "ゲームは現在利用できません。",
  "maintenance": "システムのメンテナンス。申し訳ありませんが、現在システムのメンテナンスを行っています。サービスのメンテナンスの終了後、またお会いできることをうれしく思います。ありがとうございます",
  "sessionTimeout": "非有効化のため、セッションが期限切れになりました",
  "ipBlocked": "現地の法律により、このゲームはお住まいの国ではご利用いただけません。",
  "notAvailable": "Not available"}