{
  "betDiapason": "Bahis / Havuzunuz",
  "yourBalance": "BAKİYE",
  "min": "minimum",
  "max": "maksimum",
  "auto": "Oto",
  "using": "Oyun kullanımı",
  "roomAmount": "Oda sayısı",
  "rooms": "Odalar",
  "room": "Oda",
  "memberAmount": "Katılımcı sayısı",
  "betLabelName": "İsim",
  "betLabelTime": "Zaman",
  "Ok": "Tamam",
  "betsAmount": "KAZANÇ MİKTARI",
  
  "allBets": "TÜM BAHISLER",
  "myBets": "BAHISLERIM",
  "history": "GEÇMIŞ",
  "chat": "SOHBET ODASI",
  "bet": "Bahis",
  "сhance": "Olasılık",
  "win": "Kazançlar",
  "amountOnline": "Çevrimiçi miktar",
  "chatPrompt": "Mesaj metni",
  "send": "Gönder",
  "settings": "Ayarlar",
  "changeAvatar": "Avatarı değiştir",
  "changeName": "İsmi değiştir",
  "sound": "Ses",
  "music": "Müzik",
  "animations": "Animasyonlar",
  "bonusBet": "Bonus bahis",
  "language": "Dil",
  "toHome": "Ana sayfa",
  "gettingBets": "BAHISLERI ALMA",
  "cancel": "İptal",
  "inroom": "Odadasınız:",
  "data": "Tarih",
  "checkText": "Bu ad mevcut değil, lütfen tekrar deneyin",
  "inputname": "Adınızı girin",
  "nameTaken": "Bu kullanıcı adı zaten alınmış, lütfen başka bir tane deneyin",
  "nameInvalid": "Hata. A-z, 0-9 ve _ kullanabilirsiniz. Karakter uzunluğu, 4'ten 16'ya kadar olabilir",
  "wrongDiapason": "Hata. \n Mevcut çekilişteki toplam bahis miktarı aşıldı",
  "default": "Bağlantı koptu \n Lütfen, oyunu yeniden yükleyin",
  "InsufficientFunds": "Hesabınızda para yok. \n Devam etmek için lütfen hesabınıza para yatırın.",
  "SessionOverride": "Maalesef oyunlarımızda çoklu \n   oturumlar desteklenmemektedir",
  "shutdownNotification": "Sistem bakımı var Üzgünüz, mümkün olan en kısa sürede sistem bakımını gerçekleştireceğiz. Lütfen, mevcut oyun oturumunuzu bitirin ve oyundan çıkın. Bakım sırasında oyunlarımız bir süre kullanılamayacak, bundan sonra sizi tekrar memnun etmek için geri döneceğiz!",
  "incorrectGIN": "Oyun şu anda kullanılamıyor.",
  "maintenance": "Sistem bakımı. Üzgünüz, şu anda bir sistem bakımı yapıyoruz. Yakında oyunlarımızla sizi memnun etmek için geri döneceğiz. Teşekkürler",
  "sessionTimeout": "Hareketsizlik nedeniyle oturumunuz zaman aşımına uğradı",
  "ipBlocked": "Yerel mevzuat nedeniyle bu oyun ülkenizde mevcut değildir.",
  "notAvailable": "Not available"}