{
  "betDiapason": "Aposta / Seu pool",
  "yourBalance": "Seu saldo",
  "min": "mín.",
  "max": "máx.",
  "auto": "automático",
  "using": "Usos do jogo",
  "roomAmount": "Número de salas",
  "rooms": "Salas",
  "room": "Sala",
  "memberAmount": "Número de participantes",
  "betLabelName": "Nome",
  "betLabelTime": "Tempo",
  "Ok": "Ok",
  "betsAmount": "QUANTIA DE GANHO",
  
  "allBets": "TODAS AS APOSTAS",
  "myBets": "MINHAS APOSTAS",
  "history": "HISTÓRICO",
  "chat": "BATE-PAPO",
  "bet": "Aposta",
  "сhance": "Chance",
  "win": "Vitória",
  "amountOnline": "Número online",
  "chatPrompt": "Texto",
  "send": "Enviar",
  "settings": "Configurações",
  "changeAvatar": "Mudar avatar",
  "changeName": "Mudar nome",
  "sound": "Áudio",
  "music": "Música",
  "animations": "Animações",
  "bonusBet": "Aposta Bonus",
  "language": "Idioma",
  "toHome": "Home",
  "gettingBets": "RECEBER APOSTAS",
  "cancel": "Cancelar",
  "inroom": "Você está na sala:",
  "data": "Data",
  "checkText": "Este nome não está disponível, tente novamente",
  "inputname": "Digite seu nome",
  "nameTaken": "Este nome de usuário já está em uso, por favor, tente outro",
  "nameInvalid": "Erro. Você pode usar az, 0-9 e _. Comprimento dos caracteres - de 4 a 16",
  "wrongDiapason": "Erro. \n O valor total das suas apostas no sorteio atual foi excedido",
  "default": "Conexão perdida \n Recarregue o jogo.",
  "InsufficientFunds": "Não há dinheiro na sua conta. \n Por favor, adicione fundos em sua conta para continuar.",
  "SessionOverride": "Infelizmente, as multissessões não são \n   suportadas em nossos jogos",
  "shutdownNotification": "Manutenção do sistema Desculpe, faremos a manutenção do sistema o mais rápido possível. Por favor, termine sua sessão de jogo atual e saia do jogo. Durante a manutenção, nossos jogos ficarão indisponíveis por algum tempo, depois disso voltaremos para encantá-lo novamente!",
  "incorrectGIN": "O jogo não está disponível no momento.",
  "maintenance": "Manutenção do sistema. Desculpe, estamos executando uma manutenção do sistema no momento. Estaremos de volta em breve para encantá-lo com nossos jogos. Obrigado",
  "sessionTimeout": "Sua sessão expirou devido à inatividade",
  "ipBlocked": "Devido à legislação local, este jogo não está disponível em seu país.",
  "notAvailable": "Not available"}