import BaseRequest from './BaseRequest';

export default class RequestRoomHistory extends BaseRequest {
    public value:number = 0;
    public seed:string = "";

    constructor() {
        super("roomRounds");
        // this.value = value;
        // this.seed = seed;
    }
    
    getData():any {
        let outObj = super.getData();
        outObj.data = {
            // amount: this.value,
            // seed: this.seed
        }
        return outObj;
    }
}
