
import { defineComponent } from 'vue';
import store from '../store'
import { Room } from '../store'
import i18n from '../i18n';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../assets/audio/najatie.mp3';
import device from "current-device";

export default defineComponent({
    name: 'RoomChooser',
    props: {
      
    },
     data() {
        return  { 
         roomBlocked:0,
         carentRoomType:"demo",
         selectedRoomType:'demo',
         selectedRoomName:'1',  // selectedRoomName:'', TODO
         auth:true,
         selectedTypeofRoom:'min_bet' // selectedTypeofRoom:'' TODO
        }
    },
    computed: {  
        // selectedRoomId(){ return  store.state.room.id}  ,
        room() { return store.state.room },  
        roomL() { return store.state.roomList },        
        selectedRoomId(){ return  store.state.room.name}  , 
        isDemo() { return store.state.room.type == Room.Demo },
        isMedium() { return store.state.room.type == Room.Medium },
        isHigh() { return store.state.room.type == Room.High },
        isDemoDisabled() {
            return store.state.roomTypes.findIndex((elem) => elem.type == Room.Demo) < 0;
        },
        isMediumDisabled() {
            return store.state.roomTypes.findIndex((elem) => elem.type == Room.Medium) < 0;
        },
        isHighDisabled() {
            return store.state.roomTypes.findIndex((elem) => elem.type == Room.High) < 0;
        },
        selectedTypeofRoom():string {    
            return store.state.room.type;
        },
        checkSelectRoom():boolean {    
            console.log("this.selectedRoomType ,store.state.room.type ,this.carentRoomType ,this.selectedTypeofRoom"); 
           console.log(this.selectedRoomType ,store.state.room.type ,this.carentRoomType ,this.selectedTypeofRoom);                  
           return  this.selectedTypeofRoom == this.selectedRoomType  ? true: false
        },
        deviseIos():boolean{
            return  device.type == 'desktop'
        },
        
        roomLabelDemo():string {
            // if(this.isDemoDisabled) return i18n.global.t('notAvailable');
            // if(this.isDemo && this.checkSelectRoom) return i18n.global.t('inroom');
            return i18n.global.t('roomAmount');
        },
        roomLabelMedium():string {
            // if(this.isMediumDisabled) return i18n.global.t('notAvailable');
            // if(this.isMedium && this.checkSelectRoom) return i18n.global.t('inroom');
            return i18n.global.t('roomAmount');
        },
        inroomLabel():string {
            // if(this.isMediumDisabled) return i18n.global.t('notAvailable');
            // if(this.isMedium && this.checkSelectRoom) return i18n.global.t('inroom');
             return i18n.global.t('inroom');
        },
        roomLabelHigh():string {
            // if(this.isHighDisabled) return i18n.global.t('notAvailable');
            // if(this.isHigh && this.checkSelectRoom) return i18n.global.t('inroom');
            return i18n.global.t('roomAmount');
        },
        roomAmountDemo():number|string {
            let item = store.state.roomTypes.find( (elem) => elem.type == Room.Demo);
            return item!?.count;
            //return store.state.room.id;
        },
        roomAmountMedium():number|string  {   
            let item = store.state.roomTypes.find( (elem) => elem.type == Room.Medium);
            return item!?.count;         
            //return store.state.room.id;
        },
        roomAmountHigh():number|string   {  // | undefined   or item with ! -->  item!.count; 
            let item = store.state.roomTypes.find( (elem) => elem.type == Room.High);            
            return item!?.count;   
            //return store.state.room.id;
        },
        roomList():Array<any> {
            // return store.state.roomList;
            // console.log(store.state.roomList)
             let n:any = []
            //  this.selectedRoomName = '1000'
             let self = this
             store.state.roomList.forEach(function(item , index) {
                 item['name'] = index + 1;
                 if(store.state.room.id ==  item.id) {self.selectedRoomName = item['name']}
                 n.push(item);
                 },this);
             return n
        },
        isNotactive():boolean{
            return this.roomBlocked != 0 || store.state.totalBetInRound? true:  false;
        },
        // initroom():void {
        //     this.selectedRoomName = '5'
        //     this.selectedTypeofRoom = this.selectedRoomType  
        // },
        roomTypeClass():string {
            let className = "none"
            switch(store.state.room.type) {
                case Room.Demo: className = "demo"; break;
                case Room.Medium: className = "medium"; break;
                case Room.High: className = "high"; break;
                default: className = "none";
            }            
            return store.state.roomList.length ? className : "none";
        },
        roomsLabel():string { return i18n.global.t('rooms'); },
        roomUsersAmountLabel():string { return i18n.global.t('memberAmount'); },
    }, 
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    watch: {
        'room.id'() {
            // console.log("store.state.room.id",store.state.room.id)
            // console.log("store.state.room.type",store.state.room.type)
            // console.log("store.state.roomList",store.state.roomList.length)
            this.selectedTypeofRoom = store.state.room.type
            if(this.auth) {
                store.dispatch('SERVER_BUG_CHANGE_ROOM_TYPE', store.state.room.type)
            }                 
        },
        'roomL'(){
             let self = this
            //  console.log("store.state.roomList",store.state.roomList.length)
             store.state.roomList.forEach(function(item , index) {      
                 item['name'] = index + 1;     
                //  console.log("store.state.room.id +++ store.state.room.id",item.id)    
                 if(store.state.room.id ==  item.id) {self.selectedRoomName = item.name}                 // console.log("store.state.room.id store.state.room.id",item.name, '  ', item.id )
                 },this);
           if(this.auth) {
                store.dispatch('HIDE_ROOM_LIST');  
                this.auth = false
            }      
           
        }
    },
    methods: {        
        selectRoomType(roomTypeId:string):void {
            if( store.state.room.game.bets.find(bet=>bet.me)){
                // console.log('    Error?  me inroom  ------------------------------------------------------------>');
                return 
            }
            if(this.roomBlocked) return
            const isDis = store.state.roomTypes.findIndex((elem) => elem.type == roomTypeId) < 0;  
            if(isDis) return;
            this.selectedRoomType = roomTypeId;
            store.state.optionSound && this.btnPush.play()
            // console.log(" -- - - - -- ", store.state.roomList.length)
            // console.log(" -- - - - -- ", this.selectedRoomType)
            // console.log(" -- - - - -- ", store.state.room.type != roomTypeId)
            if(!store.state.roomList?.length) store.dispatch('CHANGE_ROOM_TYPE', roomTypeId);
            else {
                if(store.state.room.type != roomTypeId) store.dispatch('HIDE_ROOM_LIST_AND_CHANGE_TYPE', roomTypeId);
                else store.dispatch('HIDE_ROOM_LIST');
            }
            // setTimeout(() => {
            //      store.dispatch('HIDE_ROOM_LIST');
            // },) 
        },
        selectRoom(roomId:string, name:string):void {    
            this.roomBlocked = setTimeout(() => {
                this.roomBlocked = 0
            }, 500);  
            this.selectedRoomName = name
            this.selectedTypeofRoom = this.selectedRoomType   
            this.selectedRoomType = store.state.room.type
            store.state.optionSound && this.btnPush.play()
            store.dispatch('HIDE_ROOM_LIST');
            store.dispatch('CHANGE_ROOM', roomId);
        }
    }
});


