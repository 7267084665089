
import Config from '@/Config';
import store from '@/store';
import Utils from '@/Utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'BetItem',
    props: ['id', 'item', 'type'],
    data() {
        return  {
            _id: this.id,
            _item: this.item
        }
    },
    computed: {       
        styleObject(){
            let image ="000"
             if(this.item.part){
                 let icon = this.item.player.icon
                 image  = require('../../assets/avatars/'+Utils.getAvatarFileNameById(icon)+'.svg')
             }       
        return {backgroundImage: 'url(' + image + ')'}
        }, 
        playerName():string {     
            return this.item.player && this.item.player.name ? this.item.player.name.substring(0, Config.PLAYER_NAME_MAX_LENGTH) : "No Win";
        },
        betValue():string {
            return  this.item.player && this.item.amount? Utils.roundMoneyAddTwo(this.item.amount): "    ";
        },
        chanceValue():string {
            return this.item.part ? (this.item.part*100).toFixed(1)+"%" :  "";
        },
        betBgColor():string {         
            if(this.item.fail) { 
                return "#101010";
            }else if(this.item.me) {
                return "#ffd421";
            }else{
                let clr = Config.getPlayerColor(this.id);
                return Utils.numToHex(clr)+"60";
            }
        },
        isLostBet():boolean{
            return  this.item.fail
        },
        isWin():boolean {
            return this.item.win > 0 && !this.type;
        },
        isWinTime():string {
            return  Utils.parseTime(this.item.ts) || '10:10:10'
        },
        isWinTimeShowing():boolean {
            return  this.type =='isHistory' ? true : false;
        },
        winValue():string {
            return this.item.win > 0 ? Utils.roundMoney(this.item.win) : "";
        }
    }
});

