import Net from "@/net/Net";

export default class BaseRequest {
    public readonly action:string;
    public readonly msgId:string;
    public onReceived!:(data:any)=>void;

    constructor(action:string) {
        this.action = action;
        this.msgId = (Date.now()*Math.random()).toString();
    }

    public exec() {
        Net.sendRequest(this, this.getData())
    }

    public startPing() {
        Net.sendPing()
    }

    protected getData():any {
        return {
            action: this.action,
            id: this.msgId
        }
    }

}
