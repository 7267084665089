import BaseRequest from './BaseRequest';

export default class RequestName extends BaseRequest {
    public name:string | undefined = "";
    public icon:string | undefined = "";

    constructor(value:any, name:string | undefined, icon:string | undefined) {  // mesach
        super("sendSettings");
        this.name = name;
        this.icon = icon;
    }
    
    getData():any {
        let outObj = super.getData();
        outObj.data = {
            name: this.name,
            icon: this.icon
        }
        return outObj;
    }
}
