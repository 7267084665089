
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store'
import BetItem from './BetItem.vue';

export default defineComponent({
    name: 'History',
    components: {
        BetItem
    },
    data() {
        return  {
            type:'isHistory'
            // betHistory: store.state.betHistory
        }
    },
    computed: {
        betHistory:():ReadonlyArray<any> => store.state.betHistory,
        betLabel():string {
            return i18n.global.t('bet');
        },
        сhanceLabel():string {
            return i18n.global.t('сhance');
        },
        winLabel():string {
            return i18n.global.t('win');
        },
        betLabelTime():string {
            return i18n.global.t('betLabelTime');
        },
        betLabelName():string {
            return i18n.global.t('betLabelName');
        },
    }
});


