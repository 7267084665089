
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';
import ChatItem from './ChatItem.vue';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'Chat',
    components: {
        ChatItem
    },
    data() {
        return  {
            chatMessages: store.state.chat.messages,
            inputMsg: "",
            isPrompt: true
        }
    },
    created() {
        this.inputMsg = i18n.global.t('chatPrompt');
    },
    updated() {
        if(this.isPrompt) this.inputMsg = i18n.global.t('chatPrompt');
    },
   
    computed: {
        btnSendLabel():string { return i18n.global.t('send'); },
        chatPromptLabel():string { return i18n.global.t('chatPrompt'); },
        amountOnlineLabel():string { return i18n.global.t('amountOnline'); }
    }, 
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    methods: {
        sendMessageDesktop(){
            // let rgEmpty = /^\s*$/;
            store.state.optionSound && this.btnPush.play()
            // !rgEmpty.test(this.inputMsg) && store.dispatch('CHAT_SEND_MSG', this.inputMsg);
            this.inputMsg.trim() !='' && store.dispatch('CHAT_SEND_MSG', this.inputMsg);   
            this.inputMsg = "";
            this.isPrompt = false;
        },
        sendMessage() {
            store.state.optionSound && this.btnPush.play()
            this.inputMsg.trim() !='' && this.inputMsg != i18n.global.t('chatPrompt') && store.dispatch('CHAT_SEND_MSG', this.inputMsg);
            this.inputMsg = i18n.global.t('chatPrompt');
            this.isPrompt = true;
        },
        onFocus() {
            if(this.isPrompt) {
                this.inputMsg = "";
                this.isPrompt = false;
            }
        },
        onBlur() {
            if(this.inputMsg == "" && !this.isPrompt) {
                this.inputMsg = i18n.global.t('chatPrompt');
                this.isPrompt = true;
            }
        }
    }
});


