
import Config from '@/Config'; 
import Utils from '@/Utils';
import { defineComponent } from 'vue';
import store from '../../store';

export default defineComponent({
    name: 'MyBetItem',
    props: ['id', 'item'],
    computed: {
        styleObjectcmpBetItemCnt(){
           let  image  = require('../../assets/avatars/'+Utils.getAvatarFileNameById(store.state.user.icon || "000")+'.svg') 
           return {backgroundImage: 'url(' + image + ')'}
        },
        betTime():string {
            return Utils.parseTime(this.item.ts);
        },
        playerName():string {
            return store.state.user.name;
        },
        betValue():string {
            return Utils.roundMoney(this.item.bet);
        },
        isFpDisable():boolean {
            return !crypto.subtle;
        },
        // chanceValue():string {
        //     return this.item.part ? (this.item.part*100).toFixed(1)+"%" : "";
        // },
        winValue():string {
            return this.item.win > 0 ? Utils.roundMoney(this.item.win) : "";
        },
        isData():boolean {
            if (!this.item.ts || !this.item.bet) console.error(" Server Error Data :");            
            return this.item.ts
        }
    },
    methods: {
        fairPlayClick():void {
            if(crypto.subtle) {
                store.commit("M_FP_BET_INDEX", this.id);
                store.commit("M_IS_MYBET_FP_OPENED", true);            
            }
        }
    }
});

