{
  "betDiapason": "Jedel / Siziň howzuňyz",
  "yourBalance": "Siziň balansyňyz",
  "min": "iň az",
  "max": "iň ýokary",
  "auto": "awto",
  "using": "Oýunda ulanylýar",
  "roomAmount": "Otaglaryň sany",
  "rooms": "Otaglar",
  "room": "Otag",
  "memberAmount": "Gatnaşyjylaryň sany",
  "betLabelName":"Ady",
  "betLabelTime": "Wagt",
  "Ok": "Bolýar",
  "betsAmount": "UTUŞYŇ MUKDARY",
   
  "allBets": "ÄHLI MÖÇBERLER",
  "myBets": "MENIŇ MÖÇBERLERIM",
  "history": "TARYHY",
  "chat": "ÇAT",
  "bet": "Möçber",
  "сhance": "Ähtimallyk",
  "win": "Utuş",
  "amountOnline": "Onlaýnyň sany",
  "chatPrompt": "Habarnamanyň ýazgysy",
  "send": "Ugratmak",
  "settings": "Sazlamak",
  "changeAvatar": "Awatary üýtgetmek",
  "changeName": "Ady üýtgetmek",
  "sound": "Ses",
  "music": "Saz",
  "animations": "Animasiýa",
  "bonusBet": "Bonus bet",
  "language": "Dil",
  "toHome": "Baş sahypa",
  "gettingBets": "MÖÇBERLERI ALMAK",
  "cancel": "Ret etmek",
  "inroom": "Siz otagda:",
  "data": "Sene",
  "checkText": "Bu at elýeter däl, ýene-de synanyşyň",
  "inputname": "Adyňyzy giriziň",
  "nameTaken": "Bu at eýýam ulanylýar, başga at saýlaň",
  "nameInvalid": "Ýalňyşlyk A-z, 0-9 we _. simwollary ulanyp bilersiňiz  Simwollaryň uzynlygy - 4-den 16-a çenli",
  "wrongDiapason": "Ýalňyşlyk \n Häzirki jedeldäki Siziň goýumlaryňyzyň umumy mukdary çägi aşýar.",
  "default": "Baglanyşyk ýitdi \n Oýuny täzeden açmagyňyzy haýyş edýäris.",
  "InsufficientFunds": "Hasabyňyzda pul ýok \n Dowam etmek üçin hasabyňyzy doldurmagyňyzy haýyş edýäris.",
  "SessionOverride": "Gynansagam, bir wagtyň özünde birnäçe sessiýa başlamak \n   oýunlarymyzda elýeter däl",
  "shutdownNotification": "Ulgam hyzmaty Ötünç soraýarys, ýakyn wagtda ulgam hyzmatyny amala aşyrarys. Häzirki oýun sessiýasyny tamamlaň we oýundan çykyň. Bejeriş işleri geçirilýän döwründe oýunlar wagtlaýynça elýeterli bolmaz. Hyzmat işleri tamamlanandan soň Siz bilen ýene-de görüşmäge şat bolarys!",
  "incorrectGIN": "Oýun häzirki wagtda elýeterli däl.",
  "maintenance": "Ulgamyň tehniki hyzmaty. Ötünç soraýarys, häzirki wagtda ulgamda tehniki hyzmat işleri geçirilýär. Hyzmat işleri tamamlanandan soň Siz bilen ýene-de görüşmäge şat bolarys! Minnetdarlyk bildirýäris",
  "sessionTimeout": "Işjeňligiň yokdugy sebäpli sessiýaňyzyň ulanyş möhleti gutardy.",
  "ipBlocked": "Ýerli kanunçylyga laýyklykda bu oýun siziň ýurduňyzda elýeterli däl.",
  "notAvailable": "Not available"}