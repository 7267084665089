import { BetVO } from '@/types';
import { Container, Graphics, Text } from 'pixi.js';
import {gsap, Sine, Linear} from 'gsap';
import store from '@/store';
import SoundManager from './SoundManager';
import Config from '@/Config';

export default class BetDisk extends Container {
    private sectors!:Map<String, number>;
    private rotableContainer!:Container;
    private linesContainer!:Container;
    private sectorsContainer!:Container;
    private textContainer!:Container;
    private addingPointer:number = 0;
    private rtTl: any;
    private soundManager :SoundManager;
    private firstSector:boolean = true;
    public onRotationState!:(state:number, duration:number)=>void;

    constructor() {
        super();
        this.rotableContainer = new Container();
        this.addChild(this.rotableContainer);

        this.sectorsContainer = new Container();
        this.rotableContainer.addChild(this.sectorsContainer);
        this.linesContainer = new Container();
        this.rotableContainer.addChild(this.linesContainer);
        
        this.textContainer = new Container();
        this.addChild(this.textContainer);
        
        this.sectors = new Map<String, number>();
        this.soundManager = new SoundManager()
    }
    public dickStopAndClear() {
        Config.IS_LOG_STATE && console.log('  |_BetDisk__dickStopAndClear',);
        this.clear()
        this.rtTl?.clear()
        this.rtTl?.kill()
        this.rtTl?.killTweensOf()
        this.rtTl?.pause()
        // gsap.killTweensOf(this.rtTl)
    }

    public dickUpdateTime(t:any){
      let time = this.rtTl?this.rtTl?.totalTime()+ t/1000:0
      time = time>11?11:time
    //   console.log("----------------",time, "   ", this.rtTl);
      //   this.rtTl.t
      this.rtTl?.seek(time)
      return 
    }

    public setAngle(angle:number, animTime = 0) {    
        Config.IS_LOG_STORE && console.log(" setAngle angle ",angle)   
        if(animTime > 0) {
            Config.IS_LOG_STATE && console.log(' |_BetDisk__setAngle');
            // this.textContainer.alpha = 0;
            this.sectorsContainer.children.forEach((item)=>{
                gsap.killTweensOf(item);
                item.alpha = 1;
            });
            this.linesContainer.children.forEach((item)=>{
                item.alpha = 1;
            });
            this.textContainer.children.forEach((item)=>{
                gsap.killTweensOf(item);
            });
            this.textContainer.removeChildren();
            ////// timeline ////   
            Config.IS_LOG_STORE && console.log(" |_BetDisk__setAngle__animTime == ",animTime);                    
            this.rtTl = gsap.timeline();            //{onComplete: ()=>{console.log("onComplete ")},onUpdate:()=>{ console.log("onUpdate ", this.rtTl.totalTime())}}
            let rc = this.rotableContainer; 
            let currA = rc.rotation;

            this.rtTl.to(rc, {
                rotation: currA - Math.PI/10,
                duration: animTime*0.1,
                ease: Linear.easeNone,   
                onStart:() => {
                    this.onRotationState(0, animTime*0.1)
                    //this.rotableContainer.cacheAsBitmap = true;
                }
            });
            this.rtTl.to(rc, {
                rotation: angle + Math.PI/10,
                duration: animTime*0.8,
                ease: Sine.easeInOut,  
                onStart:() => {
                    this.onRotationState(1, 0)
                }
            });
            this.rtTl.to(rc, {
                rotation: angle,
                duration: animTime*0.1,   
                ease: Linear.easeNone,  
                onStart:() => {
                    this.onRotationState(2, animTime*0.1)
                },           
                onComplete:() => {
                    this.rotableContainer.cacheAsBitmap = false;
                }
            });       
            // this.rtTl?.seek(10)
            // rtTl.to(this.textContainer, {alpha: 1, duration: 0.3});
            //////////////////



        } else this.rotableContainer.rotation = angle;
        this.textContainer.rotation = angle;
        this.textContainer.children.forEach((item)=>{
            item.rotation = -angle;
        });
    }

    public startPosition(angle:number) {
        this.addingPointer = angle;
    }

    public addBetSector(data:BetVO, animTime:number, onAnimComplete?:()=>void) { 
        let angleTo = this.addingPointer + data.part*Math.PI*2;
        let sectGraph = this.drawSector(angleTo, data.color);
        if(animTime > 0) sectGraph.alpha = 0;
        if(this.firstSector) {
            this.drawLine(this.addingPointer);
            this.firstSector = false;
        }
        let line = this.drawLine(angleTo);
        line.alpha = animTime ==0?1:0;
        this.sectorsContainer.addChild(sectGraph);
        let bisector = this.addingPointer + data.part*Math.PI;
        //this.sectors.set(data.id, bisector);
        if(data.part > 0.05) {
            let text = this.createText((data.part*100).toFixed(1)+"%");
            text.x = 100*Math.cos(bisector-Math.PI/2);
            text.y = 100*Math.sin(bisector-Math.PI/2);
            text.rotation = -this.textContainer.rotation;
            this.textContainer.addChild(text);
            if(animTime > 0) {
                text.alpha = 0;
                gsap.to(text, {alpha: 1, duration: 0.3, delay:0.3});
            }
        }       
        // this.soundManager.sound.play("razlojka")
        if(animTime > 0) {
            gsap.to(sectGraph, {alpha: 1, duration: 0.3, delay:animTime, onComplete: ()=> { 
                if(onAnimComplete != null) onAnimComplete();
                line.alpha = 1;
            }});
        }
        this.addingPointer = angleTo;
    }

    // public getAngleById(id:string):number {
    //     return this.sectors.get(id)!;
    // }

    public clear() {
        this.sectors.clear();
        this.addingPointer = 0;
        this.sectorsContainer.removeChildren();
        this.linesContainer.removeChildren();
        this.rotableContainer.rotation = 0;
        this.textContainer.removeChildren();
        this.textContainer.rotation = 0;
        this.textContainer.alpha = 1;
        this.firstSector = true;
        this.rotableContainer.cacheAsBitmap = false;
    }
    
    private createText(str:string) {
        let text = new Text(str, {
            fontFamily : 'Arial',
            fontSize: 16,
            fontWeight: "bold",
            fill : 0xFFFFFF,
            align : 'center'
        });
        text.anchor.set(0.5);
        return text;
    }

    public clearText() {
        this.textContainer.removeChildren();
    }
    
    private drawLine(angleTo:number):Container {
        var lineCnt = new Container();
        lineCnt.cacheAsBitmap = true;
        var line = new Graphics();
        line.lineStyle({color:0xF9D128, alpha: 0.5, width: 2});
        line.lineTo(0, -142);
        line.moveTo(0, 0);
        line.lineStyle({color:0xF9D128, width: 2});
        line.lineTo(0, -142);
        lineCnt.addChild(line);
        lineCnt.rotation = angleTo;
        this.linesContainer.addChild(lineCnt);
        return lineCnt;
    }

    private drawSector(angleTo:number, fillColor:number) {
        let graphics = new Graphics();
        graphics.beginFill(fillColor, 0.5);
        graphics.moveTo(0,0);
        graphics.lineTo(142*Math.cos(this.addingPointer-Math.PI/2),142*Math.sin(this.addingPointer-Math.PI/2));
        graphics.moveTo(0,0);
        graphics.arc(0, 0, 142, this.addingPointer-Math.PI/2, angleTo-Math.PI/2);
        graphics.endFill();
        graphics.moveTo(0,0);
        graphics.lineTo(142*Math.cos(angleTo-Math.PI/2),142*Math.sin(angleTo-Math.PI/2));
        return graphics;
    }

}