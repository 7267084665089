
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';

export default defineComponent({
    text: 'SocketClosed',
        
    data() {
        return  {            
            text: ""          
        }
    }, 
    computed: {
        
        alertMessageText():string { return store.state.alertMessage.text},     
        defaultSocketClose():string { return i18n.global.t('default');},      
        insufficientFunds():string { return i18n.global.t('InsufficientFunds');},        
        sessionOverride():string { return i18n.global.t('SessionOverride')},

        alertMessage():boolean { return store.state.alertMessage.isAlertMessage},
        showedDefaultSocketClose():boolean { return store.state.isdefaultSocketClose},      
        showedInsufficientFunds():boolean { return store.state.isInsufficientBalance},        
        showedSessionOverride():boolean { return store.state.isSessionOverride },

        // socketClose():string { return i18n.global.t('socketClosed')}    
    },
    methods: {
     clockOn(){
        store.commit("M_ALERTMESSAGEY_CLOSED");
        store.commit("M_DEFAULTSOCKETCLOSE", false);
        store.commit("M_INSUFFICIENTFUNDS", false);
        store.commit("M_SESSIONOVERRIDE", false);       
         
        // store.commit("M_SOCKET_CLOSED", false);
     }
        
    }
});


