import BaseRequest from './BaseRequest';

export default class RequestChat extends BaseRequest {
    public message:string = "";

    constructor(value:any, message:string) {  // mesach
        super("sendMessage");
        this.message = message;
    }
    
    getData():any {
        let outObj = super.getData();
        outObj.data = this.message
        return outObj;
    }
}
