
import { defineComponent } from 'vue';
import store, { MutationType, Stage } from '../store'
import i18n from '../i18n';
import Utils from '../Utils'
import Config from '@/Config';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../assets/audio/najatie.mp3';
import btnSpinSfx from '../assets/audio/spin.mp3';
import UtilsMixin from './UtilsMixin.vue';
import {gsap} from 'gsap';
//disabled
export default defineComponent({
    name: 'PanelBet',
    props: {},
    data() {
        return  {           
            inputMsg: ""
        }
    },
    computed: {
        room() { return store.state.room },
        betValue():string { 
            this.inputMsg = Utils.roundMoney(store.state.betValue);
            return Utils.roundMoney(store.state.betValue);
        },       
        betMinLabel():string {
            return i18n.global.t('min');
        },
        betMaxLabel():string {
            return i18n.global.t('max');
        },
        betAutoLabel():string {
            return i18n.global.t('auto');
        },
        isClickSpinDisabled():boolean {
            return store.state.room.game.stage != Stage.Trade;
        },
        isBetAnimation():boolean {
           return store.state.isBetAnimation;
        },
        isAutoBetAnimation():boolean {
           return store.state.isAutoPlay;
        },
        isAutoClickDoBet():boolean {
             Config.IS_LOG_DATE && console.log(store.state.isBetAnimation + " vue PanelBet-->isAutoClickDoBet    ",store.state.isAutoClickDoBet) ;
            
             function orientationDiv():any{
                let pc = 3
                return window.innerWidth > window.innerHeight ?  {x:-window.innerWidth/pc, y:0} : {y:-window.innerHeight/pc, x:0}
            }
            if(store.state.room.game.stage == Stage.Trade && store.state.isAutoClickDoBet  ) {  
                  store.state.isAutoClickDoBet = false
                  Config.IS_LOG_DATE && console.log("   vue PanelBet-->isAutoClickDoBetis ");              
                    store.state.optionSound && this.btnSpin.play() 
                    let {x,y} = orientationDiv();
                    // gsap.killTweensOf(".pBetSpibetValue");
                    let tween = gsap.to(".pBetSpibetValue", {
                        startAt: {x: 0, y: 0, opacity: 1},
                        x: x*0.75,
                        y: y*0.75, 
                        duration: 1.25,
                        onComplete: ()=> {
                            gsap.to(".pBetSpibetValue", { opacity:0, x: x, y: y, duration: 0.5 }); 
                           
                                      
                        }
                    });
            }    
           return !store.state.isAutoClickDoBet;
        },
        isBetDisabled():boolean {
           if(store.state.room.game.stage != Stage.Trade) store.state.isBetDisabled = true
           else store.state.isBetDisabled = false
           return store.state.isBetDisabled
        }
    },
     setup() {
        const btnSpin = useSound(btnSpinSfx, {volume: 1.0,interrupt: false}).sound;
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
       
        return {btnPush, btnSpin};
    },
    watch: {
        'room.id'() {
            console.log("-------------------------------------------------------------------")
            this.inputMsg = Utils.roundMoney(this.room.game.minBet);
            // store.state.betValue = this.room.game.minBet
            // store.state.betValue = this.room.game.currBet !=0? this.room.game.currBet: this.room.game.minBet
            // store.state.betValue = store.state.betValue < this.room.game.minBet ? this.room.game.minBet: store.state.betValue 
            // store.state.betValue = store.state.betValue > this.room.game.maxBet ? this.room.game.maxBet: store.state.betValue 
            // this.inputMsg = Utils.roundMoney(store.state.betValue);

            // this.room.game.currBet = 0 
            store.state.betValue = this.room.game.minBet
            this.inputMsg = Utils.roundMoney(store.state.betValue);      
            },
    },
    mounted(){
        window.addEventListener('keydown',(e)=>{
            if((e.code == 'Space' || e.keyCode == 32)){
                // this.onBlur()
                // this.clickSpin()     bug with chat
            }
        })
    },
    methods: {
        onFocus() {            
            this.inputMsg = ''
        },
        onlyNumber ($event: any) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) { // 46 is dot
                $event.preventDefault();
            }
        },
        onBlur() {                        
            if(!Number(this.inputMsg)) this.inputMsg = Utils.roundMoney(store.state.betValue).toString()            
            store.commit("M_CHANGE_BET", {
                    value: this.inputMsg.toString().replace(/,/, '.'),  // buf on bevelop type number for this.inputMsg
                    change: MutationType.Input
            });
        },
        enteronBlur() {   
            this.onBlur()
        },
        clickSpin():void {           
            function orientationDiv():any{
                let pc = 2.4
                return window.innerWidth > window.innerHeight ?  {x:-window.innerWidth/pc, y:0} : {y:-window.innerHeight/pc, x:0}
            }
            if(store.state.room.game.stage == Stage.Trade && !store.state.isBetAnimation ) {                   
                    store.state.optionSound && this.btnSpin.play() 
                    store.dispatch('DO_BET');
                    let {x,y} = orientationDiv();
                    gsap.killTweensOf(".pBetSpibetValue");
                    let tween = gsap.to(".pBetSpibetValue", {
                        startAt: {x: 0, y: 0, opacity: 1},
                        x: x*0.75,
                        y: y*0.75, 
                        duration: 1.25,
                        onComplete: ()=> {
                            gsap.to(".pBetSpibetValue", { opacity:0, x: x, y: y, duration: 0.5 });
                        }
                    });
            }    
        },
        clickInc():void {
            if(store.state.room.game.stage == Stage.Trade) {
                store.state.optionSound && this.btnPush.play()  
                store.commit("M_CHANGE_BET", {
                    value: store.state.room.game.minBet, //Config.INC_DEC_BET_STEP,
                    change: MutationType.Add
                });
            }
        },
        clickDec():void {
            if(store.state.room.game.stage == Stage.Trade) {
                store.state.optionSound && this.btnPush.play()  
                store.commit("M_CHANGE_BET", {
                    value: -store.state.room.game.minBet,//-Config.INC_DEC_BET_STEP,
                    change: MutationType.Add
                });
            }
        },
        clickMin():void {
            if(store.state.room.game.stage == Stage.Trade) {
                store.state.optionSound && this.btnPush.play()  
                store.commit("M_CHANGE_BET", {
                    value: store.state.room.game.minBet,
                    change: MutationType.Set
                });
            }
        },
        clickMax():void {
            if(store.state.room.game.stage == Stage.Trade) {
                store.state.optionSound && this.btnPush.play()
                const currentMaxBet = store.state.room.game.maxBet>store.state.user.balance ? store.state.user.balance : store.state.room.game.maxBet;
                store.commit("M_CHANGE_BET", {
                    value: currentMaxBet-store.state.totalBetInRound,
                    change: MutationType.Set
                });
            }
        },
        clickAuto():void {          
                store.state.optionSound && this.btnPush.play() 
                store.commit("M_AUTO_PLAY", !store.state.isAutoPlay);          
        },
        clickHalf():void {
            store.state.optionSound && this.btnPush.play()  
            if(store.state.room.game.stage == Stage.Trade) {
                store.commit("M_CHANGE_BET", {
                    value: 0.5,
                    change: MutationType.Mult
                });
            }
        },
        clickDouble():void {
            store.state.optionSound && this.btnPush.play()  
            if(store.state.room.game.stage == Stage.Trade) {
                store.commit("M_CHANGE_BET", {
                    value: 2,
                    change: MutationType.Mult
                });
            }
        }
    }
});


