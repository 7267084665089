
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'Changelanguage',
    lang: 'en',       
    data() {
        return  {            
            lang: "en"          
        }
    }, 
    computed: {
        // srcF():string {  
        //     let src = "ru"
        //     let r = require("../../assets/language"+ src +".svg")
        //     return r;
        // },
        cancel():string { return i18n.global.t('cancel'); },
        lang():string {
            return store.state.user.icon;
        }  
    },
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    methods: {        
        selectIcon(event: any ) {
            store.state.optionSound && this.btnPush.play()
            i18n.global.locale.value = this.lang = event.target.id
            store.state.user.leng = event.target.id
            store.commit('M_CHANGE_LANGUAGE', this.lang );
            store.commit('M_CHANGE_LANGUAGE_POPUP_IS_OPENED', !store.state.isOpenChangelanguage);
            store.commit('M_POPUP_IS_OPENED', true);
        },  
        languageCancel(){
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_LANGUAGE_POPUP_IS_OPENED', !store.state.isOpenChangelanguage);
            store.commit('M_POPUP_IS_OPENED', true);
        }
    }
});


