import { createStore, StoreOptions } from 'vuex'
import * as jsonpatch from 'fast-json-patch'
import Net from '@/net/Net';
import RequestShowRooms from '@/net/RequestShowRooms';
import RequestRoomEnter from '@/net/RequestRoomEnter';
import RequestHideRooms from '@/net/RequestHideRooms';
import RequestBet from '@/net/RequestBet';
import RequestChat from '@/net/RequestChat';
import RequestName from '@/net/RequestName';
import RequestRoomHistory from '@/net/RequestRoomHistory';
import RequestBetHistory from '@/net/RequestBetHistory';
import i18n from '../i18n';
import Utils from '../Utils'
import { ref } from 'vue';
import { useSound } from '@vueuse/sound';
import { ContextSystem } from '@pixi/core';
import backgroundSfx from './assets/audio/background.mp3';

export enum Room {
    None = "none",
    Demo = "demo",
    Medium = "min_bet",
    High = "max_bet"
}

export enum Tabs {
    AllBets = "allBets",
    MyBets = "myBets",
    History = "history",
    Chat = "chat",
    Info = "info",
    FairPlay = "fairPlay"
}

export enum LayoutMode {
    Normal = "normal",
    Expand = "expand"
}

export enum Stage {
    Idle = "idle",
    Trade = "trade",
    Preparation = "preparation",
    Landing = "landing",
    Roll = "roll",
    Award = "award"
}

export enum MutationType {
    Set = "set",
    Add = "inc",
    Mult = "mult",
    Input = "minput"
}
export type ChangeValue = {
    value:number;
    change:MutationType;
}

const maxShaLength = 13;
const rotations = { min: 3, max: 6 };
const maxNumber = parseInt(Array.from({ length: maxShaLength }).map(() => 'f').join(''), 16);

export default createStore({
    
    state: {
        user: {
            nameTaken: false,
            nameInvalid: false,
            leng: "en",
            name: "----",
            icon: '000',   
            seed: "",
            balance: 0,
            currency:{
                decimals: 0,
                code: ""
            } 
        },
        jackpot: {
            pool: 0,
            stars: 1
        },
        chat: {
            messages: [{}]
        },
        roomTypes: [
            {type: "demo", count:0 },
            {type: "min_bet", count:0 },
            {type: "max_bet", count:0 }
        ],
        roomList: [] as Array<any>,
        requestToRoom: "",
        room: {
            name:"",
            id: "",
            type: Room.None,
            game: {
                winner: {
                    name:"",
                    win: 0,
                    part: 0
                },
                minBet: 0,
                maxBet: 0,
                currBet:0,
                stages: {trade:0, roll:0, award:0},
                stage: Stage.Idle,
                zero: 0.03,
                time: {from:0, total:0, left:0}, 
                bets:[{amount:0,part:0,me:false,fail: true, player:{name:"",icon:"",color:0,id:""}}],
                hash: "",
                arrowPosition: 0
            }
        },   
        timeRoom: 0,
        timeIDRoom: 0,               
        betValue: 0,
        isAutoPlay: false,
        isMaxBet : false,
        totalBetInRound: 0,
        myBets: [{hash:"",seed:"",bet:0,win:0,rounds:[{player:"",seed:""}]}],
        betHistory: [],//[{uid:"K***5", bet:1400, part: 0.5, win:1400},{uid:"E***8", bet:1000, part: 0.05, win:0, my:true},{uid:"C***2", bet:1000, part: 0.05, win:0},{uid:"V***6", bet:3000, part: 0.15, win:0}],
        tab: Tabs.AllBets,
        layoutMode: LayoutMode.Normal,
        isPopupOpened: false,
        isMyBetFpOpened: false,
        optionSound: true,
        optionMusic: true,
        optionAnim: true,
        isPreloader:true,
        sound:{
            soundWin: false,
            soundJackpot: false,
            soundBtnClick: false,
            soundStartGame: false,
            soundEndGame: false,
            soundBet: false,
            soundRunGame: false,            
        },
        stageTotalTime:{
            pathDate: new Date(),
            trade:15000,
            preparation: 0,
            landing:4000,
            roll:11000,
            award:0
        },
        isOpenChangeName: false,
        isOpenChangeAvatar: false,
        isOpenChangelanguage: false,

        isAutoClickDoBet: false,        
        isBetAnimation: false,
        isBetDisabled: false,
        fairPlayData: {
            serverSeed: "", playerSeed1: "", playerSeed2: "",
            combySeed: "", resultHex: "", resultDecimal: 0, resultValue: 0
        },

        isSocketClosed: false,
        isdefaultSocketClose: false,
        isInsufficientBalance: false,
        isSessionOverride: false,
        alertMessage: {
            isAlertMessage:false,
            action: "ok",
            type:"",
            text:"",
        }
    },
    
    mutations: {
        M_INIT(state) {
            state.room.game.bets.length = 0;
            try {
                state.optionSound = localStorage.getItem("soundEnabled") != "false";
                state.optionMusic = localStorage.getItem("musicEnabled") != "false";
            } catch (error) {
                console.log(error);
            }
          
        },
        M_CLEAR_BETS_BEFORE_TRADING(state) {
            state.room.game.bets.length = 0;
        },
        M_REQUESTED_CHANGE_ROOM(state, toRoomId:string) {
            state.requestToRoom = toRoomId;
        },
        M_APPLY_TOTAL_DATA(state, data:any) {
           //console.log("                           M_APPLY_TOTAL_DATA                                ");            
            !data && console.error("Server Error: data: null|undefined" + "  data:"+data)
            if(data.user) {
                !data.user.name && ( data.user.name = "******")
                !data.user.icon && ( data.user.icon = "000")
            }else {
                console.log('warning    ', data.user,data);
            }
            
            !data.jackpot &&  console.error("Server Error: jackpot:null")
            !data.room &&  console.error("Server Error: room:null")

            data.jackpot && (state.jackpot = data.jackpot);
            data.user && (state.user = data.user);
            data.chat && (state.chat = data.chat);
            if( data.user?.currency){
                state.user.currency.decimals = data.user.currency.decimals
                state.user.currency.code = data.user.currency.code               
            }
            data.roomTypes && (state.roomTypes = data.roomTypes);
            if(data.room) {
                state.totalBetInRound = 0;
                data.room.game.currBet = data.room.game.currBet? data.room.game.currBet: data.room.game.minBet;
                state.room = data.room;
                state.betValue = data.room.game.minBet;
                // console.log('-------------------------=================',state);      
                //this.commit("M_CALC_BET_PARTS",state);      
                // this.M_CALC_BET_PARTS(state); 
                if(data?.room?.game?.bets?.length) {  
                    const game = state.room.game;
                    const sum = game.bets
                        // @ts-ignore
                        .filter(bet => !bet.fail)
                        .reduce((sum, bet) => sum + bet.amount, 0);
                    // game.winner.win = sum
                    game.bets.forEach((item)=> {            
                        if(item.me){
                          state.betValue = item.amount
                          state.totalBetInRound = item.amount
                        } 
                    })  
                    game.bets.forEach(bet=> bet.part = bet.amount * (1 - game.zero) / sum);
                }
            };
            
           
        },
       
        M_CHANGE_BY_PATCH(state, patchData:any) {            
            // if(state?.room?.game){
            //   state.stageTotalTime.pathDate = new Date()
            //   console.log(" stage  ",state?.room?.game.stage)
            //   console.log(" time left ",state?.room?.game.time.left)
            // }
            jsonpatch.applyPatch(state, patchData);
            if (!state?.room?.game?.bets?.length) return;
            const game = state.room.game;
            const sum = game.bets
                // @ts-ignore
                .filter(bet => !bet.fail)
                .reduce((sum, bet) => sum + bet.amount, 0);
            game.bets.forEach(bet=> bet.part = bet.amount * (1 - game.zero)/sum);
            // game.winner.win = sum
            // console.log(" M_CHANGE_BY_PATCH_game.winner.win == "game.winner.win);
            
            //@ts-ignore
            game.bets.filter(bet => bet.fail).forEach(bet => bet.part = 0);
        },
        // M_JACKPOT_UP(state, newVal:number) {
        //     jsonpatch.applyPatch(state, [{op:"replace", path:"/jackpot/pool", value: newVal}])
        // },
        M_CHANGE_ROOM_TYPE(state, roomTypeId:Room) {
            state.room.type = roomTypeId;
        },
        M_CHANGE_ROOM_LIST(state, roomList:Array<any>) {
            state.roomList = roomList;
        },
        M_CHANGE_TAB(state, tabId:Tabs) {
            state.tab = tabId;
        },
        M_AUTO_PLAY(state, boolean:boolean) {
            state.isAutoPlay = boolean;
        },
        M_CHANGE_BET(state, chVal:ChangeValue) {
            function checkBetValue (m:number){
                state.betValue <= state.room.game.minBet? state.betValue = state.room.game.minBet: null
                state.betValue >= state.room.game.maxBet? state.betValue = state.room.game.maxBet: null
                state.betValue >= state.user.balance ? state.betValue = state.user.balance : null
                state.betValue = Number(Math.trunc(state.betValue))
                // m == 0.5? (state.betValue = Number(state.betValue.toFixed(0))): null
            }
            switch(chVal.change) {
                case MutationType.Input:
                    state.betValue = chVal.value *10**state.user.currency.decimals; 
                    checkBetValue(chVal.value) 
                    break;
                case MutationType.Set:    
                    state.betValue = chVal.value;                   
                    checkBetValue(chVal.value) 
                    break;
                case MutationType.Add:
                    state.betValue += chVal.value;      
                    checkBetValue(chVal.value)                              
                    break;
                case MutationType.Mult:                  
                    state.betValue *= chVal.value;
                    checkBetValue(chVal.value) 
            }
        },
        M_ADD_CHAT_MSG(state, chatItem) {
            state.chat.messages.push(chatItem);
        },
        M_CHANGE_LAYOUT(state, lmode:LayoutMode) {
            state.layoutMode = lmode;
        },
        M_POPUP_IS_OPENED(state, isOpened:boolean) {
            state.isPopupOpened = isOpened;
        },
        M_IS_MYBET_FP_OPENED(state, isOpened:boolean) {
            state.isMyBetFpOpened = isOpened;
        },
        M_FP_BET_INDEX(state, index:number) {          
            let fpItem = state.myBets[index];
            state.fairPlayData.serverSeed = fpItem.seed;
            state.fairPlayData.playerSeed1 = fpItem.rounds[0]?.seed;
            state.fairPlayData.playerSeed2 = fpItem.rounds[1]?.seed;
            // state.fairPlayData.playerSeed3 = fpItem.rounds[2].seed;
            const randomString = fpItem.seed + fpItem.rounds[0]?.seed + fpItem.rounds[1]?.seed  //+ fpItem.rounds[2].seed;
            state.fairPlayData.combySeed = randomString;
            const encodedRandomString = new TextEncoder().encode(randomString);
            crypto.subtle.digest('SHA-512', encodedRandomString).then(hashBuffer => {
                const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                state.fairPlayData.resultHex = hashHex;
                const num = parseInt(hashHex.slice(0, maxShaLength), 16);
                state.fairPlayData.resultDecimal = num;
                const randomNumber = num / maxNumber; // [0; 1)
                state.fairPlayData.resultValue = 2 * Math.PI * (rotations.min + randomNumber * (rotations.max - rotations.min));
            })
        },
        M_CHANGE_ICON(state, iconNum:string) {
            state.user.icon = iconNum;
        },        
        M_CHANGE_ICON_POPUP_IS_OPENED(state, isOpened:boolean) {
            state.isOpenChangeAvatar = isOpened;
        },        
        M_CHANGE_NAME(state, isOpened:boolean) {
           //console.log("M_CHANGE_NAME");            
        },
        M_CHANGE_LANGUAGE(state, islanguage:boolean) {
           //console.log("M_CHANGE_LANGUAGE");            
        },
        M_CHANGE_NAME_POPUP_IS_OPENED(state, isOpened:boolean) {
            state.user.nameInvalid = false
            state.user.nameTaken = false
            state.isOpenChangeName = isOpened;            
        },
        M_CHANGE_LANGUAGE_POPUP_IS_OPENED(state, isOpened:boolean) {
            state.isOpenChangelanguage = isOpened;
        },
        M_OPTION_SOUND(state, isSelected:boolean) {
            // let audioObj = new Audio(require('../assets/audio/background.mp3'));
           //console.log("M_OPTION_SOUND: "+ isSelected);
            state.optionSound = isSelected;
            try {
                localStorage.setItem("soundEnabled", isSelected ? "true" : "false");  
            } catch (error) {
                console.log(error);                
            }
           
        },
        M_OPTION_MUSIC(state, isSelected:boolean) {
            state.optionMusic = isSelected;
            try {
                localStorage.setItem("musicEnabled", isSelected ? "true" : "false");
            } catch (error) {
                console.log(error);    
            }
           
        },
        M_OPTION_ANIM(state, isSelected:boolean) {
            state.optionAnim = isSelected;
        },
        // M_CALC_BET_PARTS(state) {
        //     let sum = 0;
        //     for (let i = 0; i < state.room.game.bets.length; i++) {
        //         sum += state.room.game.bets[i].amount;
        //         console.log( state.room.game.bets[i].amount)
        //     }
        //     if(sum == 0) return;
        //     // sum = sum + sum*state.room.game.zero;
        //     console.log( state.room.game.bets.map((bet)=>bet.part));
        //     state.room.game.bets.forEach((item)=>{                
        //     item.part = item.amount * (1 - state.room.game.zero)/sum;
        //     });
        //     console.log( state.room.game.bets.map((bet)=>bet.part));
        // },
        // M_SOCKET_CLOSED(state, boolean) { 
        //     state.isSocketClosed = boolean
        // },
        M_DEFAULTSOCKETCLOSE(state, boolean) {
            console.log("M_DEFAULTSOCKETCLOSE");   
            state.isdefaultSocketClose = boolean   
            state.isdefaultSocketClose && (Net.instance.wsClosed(),  state.alertMessage.action = 'reload')           
            // Net.instance.wsClosed()
            // state.alertMessage.action = 'reload'
            // Net.prototype.onClose(null)
            // // @ts-ignore
            // Net.prototype.socket.close;
        },
        M_INSUFFICIENTFUNDS(state, boolean) { 
            state.isInsufficientBalance = boolean
        },
        M_SESSIONOVERRIDE(state, boolean) { 
            state.isSessionOverride = boolean           
        },
        M_ALERTMESSAGEY_TOTAL_DATA(state, d:any) {          
            
            state.isSocketClosed = false,
            state.isdefaultSocketClose =  false,
            state.isInsufficientBalance =  false,
            state.isSessionOverride =  false  
            
            state.alertMessage.isAlertMessage = true
            state.alertMessage.action = d.data.action.toString().toLowerCase()
            state.alertMessage.text = i18n.global.t(d.data?.type.toString())? i18n.global.t(d.data.type.toString()):  d.data.text

           
        },
        M_GOTOHOME(state) {
           location.href = new URLSearchParams(window.location.search).get("home") || "#";
        },
        M_ALERTMESSAGEY_CLOSED(state) {
            switch(state.alertMessage.action) {
                case "ok":
                    state.alertMessage.isAlertMessage = false                   
                    break
                case "reload":
                    location.href = location.href;
                    break
                case "exit":
                    location.href = new URLSearchParams(window.location.search).get("home") || "about:blank";
                    break
                default:
            }
        }
    },
    
    
    actions: {
        SOCKET_CLOSED(context, data:any) {
           context.state.isSocketClosed = true
        },
        AUTH_DATA(context, data:any) {
            context.commit("M_INIT");
            context.commit("M_APPLY_TOTAL_DATA", data);
        },
        CHAT_SEND_MSG(context, msg:string) {           
            var date = new Date();
            var h = date.getHours();
            var m = date.getMinutes();
            var s = date.getSeconds();      
            // debugger
            // context.commit('M_ADD_CHAT_MSG', {               
            //     time:  date, //(h<10?"0":"")+h+":"+(m<10?"0":"")+m+":"+(s<10?"0":"")+s,
            //     user:"G***9",
            //     text:msg
            // });
            let req = new RequestChat(context, msg);            
            req.onReceived = (obj)=> { 
            }
            req.exec();
        },
        CHANGE_NAME(context, name: string) { 
            // context.state.user.name = name   // TODO  dell
            context.state.user.nameTaken = false
            context.state.user.nameInvalid = false
            let req = new RequestName(context, name , undefined);
            req.onReceived = (obj)=> { 
                console.log("-----",obj);  
                // !obj.data.name && (console.log("-                      -   server error:  name: null"))
                if(obj.data){
                    !obj.data.name && (console.log("-                      -   server error:  name: null"))
                    context.state.user.name = obj.data.name 
                    context.commit('M_CHANGE_NAME_POPUP_IS_OPENED', !context.state.isOpenChangeName);
                    context.commit('M_POPUP_IS_OPENED', true);  
                } 
                if(obj.error){
                                     
                    obj.error.code == "nameTaken" && (context.state.user.nameTaken = true)
                    obj.error.code == "nameInvalid" && (context.state.user.nameInvalid = true)
                } 
            }
            req.exec();
        },
        CHANGE_ICON(context, icon: string) {             
            let req = new RequestName(context, undefined , icon);
            req.onReceived = (obj)=> { 
                !obj.data.icon && (console.log("server error:  icon: null"))
                context.state.user.icon = obj.data.icon
                ////console.log("RequestName RequestName")          
            }
            req.exec();
        },
        CHANGE_ROOM_TYPE(context, roomTypeId:string) {
            context.commit('M_CHANGE_ROOM_TYPE', roomTypeId);
            let req = new RequestShowRooms(roomTypeId);
            req.onReceived = (obj)=> {
                context.commit('M_CHANGE_ROOM_LIST', obj.data);
            }
            req.exec();
        },
        SERVER_BUG_CHANGE_ROOM_TYPE(context, roomTypeId:string) {
            let req = new RequestShowRooms(roomTypeId);
            req.onReceived = (obj)=> {
                context.commit('M_CHANGE_ROOM_LIST', obj.data);
            }
            req.exec();
        },
        HIDE_ROOM_LIST(context) {
            context.commit('M_CHANGE_ROOM_LIST', []);
            let req = new RequestHideRooms();
            req.onReceived = (obj)=> {
                if(obj.data) context.commit("M_APPLY_TOTAL_DATA", obj.data);
            }
            req.exec();
        },
        HIDE_ROOM_LIST_AND_CHANGE_TYPE(context, roomTypeId:string) {
            context.commit('M_CHANGE_ROOM_LIST', []);
            let req = new RequestHideRooms();
            req.onReceived = (obj)=> {
                context.commit('M_CHANGE_ROOM_TYPE', roomTypeId);
                let req = new RequestShowRooms(roomTypeId);
                req.onReceived = (obj)=> {
                    context.commit('M_CHANGE_ROOM_LIST', obj.data);
                }
                req.exec();
            }
            req.exec();
        },
        CHANGE_ROOM(context, roomId:string) {
           //console.log("   CHANGE_ROOM  ");            
            let req = new RequestRoomEnter(roomId);
            req.onReceived = (obj)=> {
                context.commit("M_APPLY_TOTAL_DATA", obj.data);
            }
            req.exec();
            context.commit("M_REQUESTED_CHANGE_ROOM", roomId);
        },
        DO_BET(context) {
               
            let dobet = (context.state.room.game.maxBet - context.state.totalBetInRound >= context.state.betValue) //||(context.state.totalBetInRound + context.state.betValue >= context.state.user.balance)
            
            if ( context.state.betValue > context.state.user.balance) {
                context.commit("M_INSUFFICIENTFUNDS", true)
                return
            }    
            if ((context.state.user.balance - context.state.totalBetInRound <=  context.state.room.game.minBet)|| (context.state.user.balance - context.state.totalBetInRound - context.state.betValue)<0) {  //context.state.user.balance - context.state.totalBetInRound <=0
                context.commit("M_INSUFFICIENTFUNDS", true)
                return
            }    

            if(context.state.betValue <=0) return

            if (context.state.totalBetInRound > context.state.room.game.maxBet || !dobet ) {
                context.state.isMaxBet = true
                return
            }
                

            if (dobet) {
                context.state.totalBetInRound+=context.state.betValue 
                
                let idTime = setTimeout(() => {
                    context.state.isBetAnimation = false
                }, 1000);

                context.state.isBetAnimation = true
                let req = new RequestBet(context.state.betValue, context.state.user.seed);
                req.onReceived = (obj)=> {               
                }
                req.exec();
            }
        },
        CHANGE_TAB(context,tabId:Tabs) {
            if(tabId == Tabs.MyBets ) {
                let reqBetHistory = new RequestBetHistory(context.state.user.seed);
                reqBetHistory.onReceived = (obj)=> {
                    context.state.myBets = obj.data
                }
                reqBetHistory.exec();
            }
            if(tabId == Tabs.History ) {
                let reqRoomHistory = new RequestRoomHistory();
                reqRoomHistory.onReceived = (obj)=> {
                    context.state.betHistory = obj.data
                    ////console.log("----- RequestRoomHistory",obj);
                }
                reqRoomHistory.exec();
            }
        }
    },
    
    modules: {
    }
});

