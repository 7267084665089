
import Config from '@/Config';
import store from '@/store';
import Utils from '@/Utils';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ChatItem',
    props: ['item'],
    data() {
        // // this.item.time = new Date(this.item.time);
        // return  {
        //     dataItem: this.item
        // }
    },
    computed: {
        msgTime (): any  {
            return Utils.parseTime(this.item.time);
        },
        playerName (): string  {
            return this.item.user.substring(0, Config.PLAYER_NAME_MAX_LENGTH);
        }
    // myText(){
    //  if (this.item.name == store.user.name) {
    //      this.item.text   
    //     }
    // }
        
    }
});


