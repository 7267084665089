import store from '@/store';
import PIXI from 'pixi.js';
import { sound } from '@pixi/sound';
import { useSound } from '@vueuse/sound';
import razlojkaSfx from '../assets/audio/razlojka.mp3'
import vrascheniye_barabanaSfx from '../assets/audio/vrascheniye_barabana.mp3';
import nachaloSfx from '../assets/audio/nachalo.mp3';
import winnerSfx from '../assets/audio/winner.mp3';

export default class SoundManager {
    
    public  sound: any
    public  razlojka: any
    public  winner: any
    public  vraschen: any
    public  nachalo: any
    public  vrascheniye_barabana: any


    constructor() {
         this.sound = sound
         sound.add('winner', require('../assets/audio/winner.mp3'));
         sound.add('vrascheniye_barabana', require('../assets/audio/vrascheniye_barabana.mp3'));
         sound.add('nachalo', require('../assets/audio/nachalo.mp3'));
         sound.add('razlojka', require('../assets/audio/razlojka.mp3'));  
         
         this.razlojka = useSound(razlojkaSfx, {volume: 1.0,interrupt: false}).sound;
         this.winner = useSound(winnerSfx, {volume: 1.0,interrupt: false}).sound;
         this.vrascheniye_barabana = useSound(vrascheniye_barabanaSfx, {volume: 1.0,interrupt: false}).sound;
         this.nachalo = useSound(nachaloSfx, {volume: 0.7, interrupt: false}).sound;
    }
    
   
    public soundStopAll (){
        this.sound.stop('winner')
        this.sound.stop('vrascheniye_barabana')
        this.sound.stop('nachalo')
        this.sound.stop('razlojka')
    }




}