
import { defineComponent } from 'vue';
import store from '../store';
import {LayoutMode} from '../store';
import i18n from '../i18n';
import Utils from '../Utils';
import { Tabs } from '../store';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'PanelGame',
    props: {},
    computed: {
        balanceValue():string {
            return Utils.roundMoney(store.state.user.balance)+ " "+ store.state.user.currency.code;
        },
        betDiapason():string {
            return Utils.roundMoney(store.state.room.game.minBet) + " — "
            + Utils.roundMoney(store.state.room.game.maxBet);
        },
        totalBetDiapason():string {
            // return Utils.roundMoney(store.state.betValue) + " / "
            // + Utils.roundMoney(store.state.room.game.maxBet);
             return Utils.roundMoney(store.state.totalBetInRound) + " / "
            + Utils.roundMoney(store.state.room.game.maxBet);
        },
        betDiapasonLabel():string {
            return i18n.global.t('betDiapason');
        },        
        balanceLabel():string {
            return i18n.global.t('yourBalance');
        },
    },
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    methods: {
        clickInfo():void {
            store.state.optionSound && this.btnPush.play()  
            store.commit('M_CHANGE_TAB', Tabs.Info);
            store.commit('M_CHANGE_LAYOUT', LayoutMode.Expand);
        },
        clickSettings():void {
            store.state.optionSound && this.btnPush.play()              
            store.commit('M_POPUP_IS_OPENED', true);
        }
    }
})
