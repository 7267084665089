{
  "betDiapason": "Mərc/qoyduğun məbləğ",
  "yourBalance": "Sizin balansınız",
  "min": "dəq.",
  "max": "maks.",
  "auto": "avto.",
  "using": "Oyun istifadə edir",
  "roomAmount": "Otaqların miqdarı",
  "rooms": "Otaqlar",
  "room": "Otaq",
  "memberAmount": "İştirakçıların sayı",
  "betLabelName": "Ad",
  "betLabelTime": "Vaxt",
  "Ok": "Ok",
  "betsAmount": "Uduş məbləği",
   
  "allBets": "BÜTÜN MƏRCLƏR",
  "myBets": "MƏNIM MƏRCLƏRIM",
  "history": "TARIX",
  "chat": "ÇAT",
  "bet": "Mərc",
  "сhance": "Ehtimal",
  "win": "Qazanc",
  "amountOnline": "Onlayn istifadəçilərin sayı",
  "chatPrompt": "Mesajın mətni",
  "send": "Göndər",
  "settings": "Kökləmə",
  "changeAvatar": "Avatarı dəyişmək",
  "changeName": "Adı dəyişmək",
  "sound": "Səs",
  "music": "Musiqi",
  "animations": "Animasiya",
  "bonusBet": "Bonus mərc",
  "language": "Dil",
  "toHome": "Əsas səhifəyə",
  "gettingBets": "MƏRCLƏRIN ALINMASI",
  "cancel": "Ləğv etmək",
  "inroom": "Siz otaqdasınız:",
  "data": "Tarix",
  "checkText": "Bu ad əlçatmazdır, bir daha cəhd edin",
  "inputname": "İstifadəçi adını daxil edin",
  "nameTaken": "Bu istifadəçi adı məşğuldur, zəhmət olmasa başqasını seçin",
  "nameInvalid": "Yanlış. Siz a-z, 0-9 və _ nişanlarını istifadə edə bilərsiniz. Simvolların uzunluğu - 4-dən 16-ya qədər",
  "wrongDiapason": "Yanlış. \n Cari oyunda ümumi mərc məbləğinin həddi aşıldı",
  "default": "Bağlantı itirildi \n Zəhmət olmasa oyunu yenidən yükləyin.",
  "InsufficientFunds": "Hesabınızda pul vəsaiti yoxdur. \n Zəhmət olmasa, davam etmək üçün hesabınızı doldurun.",
  "SessionOverride": "Təəssüf ki, eyni anda birdən çox sessiyayın işə salınması \n   bizim oyunlarda mümkün deyil.",
  "shutdownNotification": "Sistem xidməti. Üzr istəyirik, qısa müddətdə sistemə xidmət göstərməklə məşğul olacayıq. Cari oyun sessiyasını tamamlayın və oyundan çıxın. Profilaktik işlərin həyata keçirilməsi müddəti ərzində oyunlar əlçatmaz olacaq. Xidmət bitdikdən sonra sizi yenidən görməyə məmnun olacayıq!",
  "incorrectGIN": "Hal-hazırda oyun əlçatmazdır.",
  "maintenance": "Sistemin texniki xidməti. Üzr istəyirik, hal-hazırda biz sistemin texniki xidmətini həyata keçiririk. Xidmət bitdikdən sonra sizi yenidən görməyə məmnun olacayıq! Təşəkkür edirik",
  "sessionTimeout": "Sizin seansınız qeyri-aktivlik səbəbindən başa çatdı",
  "ipBlocked": "Yerli qanunvericiliyə müvafiq olaraq, bu oyun sizin ölkənizdə əlçatan deyil.",
  "notAvailable": "Not available"}