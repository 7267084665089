
<script>
import store from "../store";


export default {
 
  methods: {
    centsToMoney (value) {
      const decimals = store.state.user.currency.decimals;
      return (value / 10**decimals).toFixed(decimals)
    },
    roundMoney(money) {
        const decimals = store.state.user.currency.decimals;
        return money.toFixed(decimals);
    }
  }
}
</script>
