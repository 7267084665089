{
    "betDiapason": "Ставка / Ваш пул",
    "yourBalance": "Ваш баланс",
    "winner": "winner",
    "noWin": "no win",
    "min": "мін",
    "max": "макс",
    "auto": "aвто",
    "using": "Гра використовує",
    "roomAmount": "Кількість кімнат",
    "rooms": "КІМНАТИ",
    "room": "Кімната",
    "memberAmount": "Кількість учасників",
    "betsAmount": "СУМА ВИГРАШУ",
    "allBets": "УСІ СТАВКИ",
    "myBets": "МОЇ СТАВКИ",
    "history": "ІСТОРІЯ",
    "chat": "ЧАТ",
    "bet": "Ставка",
    "betLabelName": "Ім'я",
    "betLabelTime": "Час",
    "сhance": "Ймовірність",
    "Ok": "Ok",
    "win": "Виграш",
    "amountOnline": "Кількість онлайн",
    "chatPrompt": "Текст повідомлення",
    "send": "Відправити",
    "settings": "Налаштування",
    "changeAvatar": "Змінити аватар",
    "changeName": "Змінити ім'я",
    "sound": "Звук",
    "music": "Музика",
    "animations": "Анімації",
    "bonusBet": "Бонус бет",
    "language": "Мова",
    "toHome": "На головну",
    "gettingBets": "ОТРИМАННЯ СТАВОК",
    "cancel": "Відміна",
    "inroom": "Ви у кімнаті:",
    "data": "Дата",
    "hashTitle": "Сід поточного розіграшу :",
    "checkText": "Це ім'я недоступне, спробуйте ще раз",
    "seedServer":"СІД СЕРВЕРА",
     "seedPlayer1":"СІД ГРАВЦЯ 1",
     "seedPlayer2":"СІД ГРАВЦЯ 2 ",
     "sha512":"HASHED IN 512 ",
     "result":"Результат:",
     "whatIsFairPlay":"Що таке",
    "inputname": "Введіть своє ім'я",
    "nameTaken": "Це ім'я користувача вже зайнято, спробуйте інше",
    "nameInvalid": "Помилка. Ви можете використовувати латинські літери, 0-9 і _. Довжина символів - від 4 до 16",
    "wrongDiapason": "Помилка. \n Перевищена загальна сума Ваших ставок в поточному розіграші",
    "default": "З'єднання перервано \n Просимо перезавантажити гру",
    "InsufficientFunds": "На вашому рахунку немає коштів. \n Необхідно поповнити рахунок, щоб продовжити",
    "SessionOverride": "Нажаль, декілька одночасних сесій \n   в наших іграх не підтримуються",
    "shutdownNotification": "Технічне обслуговування системи. Вибачте, найближчим часом ми будемо проводити технічне обслуговування системи. Просимо зберегти поточну сесію та вийти з гри. Під час технічного обслуговування, деякий час ігри будуть недоступні. Ми будемо раді бачити Вас знову після завершення обслуговування!",
    "incorrectGIN": "Гра тимчасово недоступна",
    "maintenance": "Системне обслуговування. Вибачте, наразі ми проводимо технічне обслуговування системи. Ми будемо раді бачити Вас знову після завершення обслуговування. Дякуємо",
    "sessionTimeout": "Вашу сесію було завершено через відсутність активності",
    "ipBlocked": "Згідно з місцевим законодавством, ця гра недоступна у вашій країні",
    "notAvailable": "Not available"}