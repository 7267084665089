{
  "betDiapason": "Zakład / Twoja pula",
  "yourBalance": "Twój balans",
  "min": "min",
  "max": "maks",
  "auto": "auto",
  "using": "Gra wykorzystuje",
  "roomAmount": "Liczba pokoi",
  "rooms": "Pokoje",
  "room": "Pokój",
  "memberAmount": "Liczba uczestników",
  "betLabelName": "Nazwa",
  "betLabelTime": "Czas",
  "Ok": "Ok",
  "betsAmount": "STAWKA WYGRANEJ",
   
  "allBets": "Wszystkie zakłady",
  "myBets": "MOJE ZAKŁADY",
  "history": "HISTORIA",
  "chat": "CZAT",
  "bet": "Stawka",
  "сhance": "Prawdopodobieństwo",
  "win": "Wygrana",
  "amountOnline": "Ilość online",
  "chatPrompt": "Tekst wiadomości",
  "send": "Wysłać",
  "settings": "Ustawienia",
  "changeAvatar": "Zmień awatar",
  "changeName": "Zmień nazwę",
  "sound": "Dźwięk",
  "music": "Muzyka",
  "animations": "Animacje",
  "bonusBet": "Zakład bonusowy",
  "language": "Język",
  "toHome": "Do głównej",
  "gettingBets": "OTRZYMYWANIE STAWEK",
  "cancel": "Anulowanie",
  "inroom": "Jesteś w pokoju:",
  "data": "Data",
  "checkText": "Ta nazwa jest niedostępna, spróbuj ponownie",
  "inputname": "Wpisz swoje imię",
  "nameTaken": "Ta nazwa użytkownika jest już zajęta, spróbuj użyć innej",
  "nameInvalid": "Błąd. Możesz użyć a-z, 0-9 i _. Długość znaków - od 4 do 16",
  "wrongDiapason": "Błąd. \n Całkowita kwota Twoich zakładów w bieżącej rozgrywce została przekroczona",
  "default": "Utracone połączenie \n Uruchom grę ponownie.",
  "InsufficientFunds": "Brak środków na Twoim koncie. \n Doładuj konto, aby kontynuować.",
  "SessionOverride": "Niestety uruchamianie wielu sesji jednocześnie \n   jest niedostępne w naszych grach.",
  "shutdownNotification": "Konserwacja systemu Przepraszamy, trwają prace konserwacyjne w naszym systemie. Zakończ bieżącą sesję gry i wyjdź z gry. Podczas prac konserwacyjnych gry będą przez pewien czas niedostępne. Zapraszamy ponownie po zakończeniu prac konserwacyjnych!",
  "incorrectGIN": "Gra jest obecnie niedostępna.",
  "maintenance": "Konserwacja systemu. Przepraszamy, aktualnie przeprowadzamy konserwację systemu. Zapraszamy ponownie po zakończeniu prac konserwacyjnych! Dziękujemy",
  "sessionTimeout": "Twoja sesja wygasła z powodu braku aktywności",
  "ipBlocked": "Ze względu na lokalne przepisy ta gra nie jest dostępna w Twoim kraju.",
  "notAvailable": "Not available"}