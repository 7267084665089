import store from "./store";
export default class Utils {
    public static currenseDecimals: number;
   
    public static centsToMoney(cents:number):string {
        this.currenseDecimals = store.state.user.currency.decimals;
        return (cents/(10**this.currenseDecimals)).toFixed(this.currenseDecimals);
    }

    public static roundMoney(money:number):string {
        this.currenseDecimals = store.state.user.currency.decimals;
        return (money/(10**this.currenseDecimals)).toFixed(this.currenseDecimals);
        // return money.toFixed(this.currenseDecimals);
    }

    public static roundMoneyAddTwo(money:number):string {
        this.currenseDecimals = store.state.user.currency.decimals;
        return (money/(10**this.currenseDecimals)).toFixed(this.currenseDecimals+2);
        // return money.toFixed(this.currenseDecimals);
    }

    public static isLandscape():boolean {
        return window.innerWidth >= window.innerHeight;
    }

    public static isPortrait():boolean {
        return window.innerWidth < window.innerHeight;
    }

    public static numToHex(num:number) {
        if(!num) return 'gray';
        let str = num.toString(16);
        while(str && str.length < 6) str = "0"+str;
        return "#"+str;
    }

    public static getAvatarFileNameById(s:string):string {
        while(s.length < 3) s = "0"+s;
        if(s.length > 3) {console.error('Server Error with icon name',s), s = '000'}
        return s;
    }

    public static parseTime(timestamp:number):string {
        let t = new Date(timestamp);
        let h = t.getUTCHours();
        let m = t.getUTCMinutes();
        let s = t.getUTCSeconds();
        return (h < 10 ? "0" : "") + h + ':' +
               (m < 10 ? "0" : "") + m + ':' +
               (s < 10 ? "0" : "") + s;
    }


}