import BaseRequest from './BaseRequest';

export default class RequestHideRooms extends BaseRequest {

    constructor() {
        super("hideRooms");
    }

    getData():any {
        let outObj = super.getData();
        return outObj;
    }
}

// {"action":"hideRooms","id":1630410396346.7756}