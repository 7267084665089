{
  "betDiapason": "Garov/ sizning po'lingiz (tavakkalli o'yin uslubi)",
  "yourBalance": "Sizning balansingiz",
  "min": "daq.",
  "max": "maks.",
  "auto": "avto",
  "using": "O'yin ....dan foydalanadi",
  "roomAmount": "Xonalar soni",
  "rooms": "Xonalar",
  "room": "Xona",
  "memberAmount": "Qatnashchilar soni",
  "betLabelName": "Ismi ",
  "betLabelTime": "Vaqti",
  "Ok": "Ok (yaxshi)",
  "betsAmount": "Yutuq miqdori",
  
  "allBets": "BARCHA GAROVLAR",
  "myBets": "MENING GAROVLARIM",
  "history": "TARIX",
  "chat": "CHAT (MULOQOT)",
  "bet": "Garov",
  "сhance": "Ehtimoli",
  "win": "Yutuq",
  "amountOnline": "Onlaynlarining soni",
  "chatPrompt": "Xabar matni",
  "send": "Jo'natish",
  "settings": "Sozlamalar",
  "changeAvatar": "Avatarni o'zgartirish",
  "changeName": "Ismni o'zgartirish",
  "sound": "Tovush",
  "music": "Musiqa",
  "animations": "Animatsiyalar",
  "bonusBet": "Bonus garovi",
  "language": "Til ",
  "toHome": "Asosiyga",
  "gettingBets": "GAROVLARNI OLISH",
  "cancel": "Bekor qilish",
  "inroom": "Siz ...xonadasiz",
  "data": "Sana",
  "checkText": "Bu ismdan foydalanish mumkin emas, yana urinib ko'ring",
  "inputname": "Ismingizni kiriting",
  "nameTaken": "Bu foydalanuvchi nomi band, boshqasini kiritishga urinib ko'ring",
  "nameInvalid": "Xatolik Siz az, 0-9 va _ dan foydalanishingiz mumkin. Belgilar uzunligi - 4 dan 16 gacha",
  "wrongDiapason": "Xatolik \n Joriy o'yindagi Garovlaringizning umumiy summasi oshib ketgan ",
  "default": "Aloqa yo'qoldi \n Iltimos o'yinni qayta yuklang.",
  "InsufficientFunds": "Sizning hisobingizda pul yo'q \n Iltimos, davom etish uchun hisobingizni to'ldiring.",
  "SessionOverride": "Afsuski, bir vaqtning o'zida bir nechta seansning ishga tushirilishi \n   bizning o'yinlarda mavjud emas.",
  "shutdownNotification": "Tizimga xizmat ko'rsatish, Uzr so'raymiz, biz yaqin orada tizimga xizmat ko'rsatish bilan shug'ullanamiz. Joriy o'yin sessiyasini yakunlang va o'yindan chiqing. Profilaktika ishlari olib borish vaqtida, bir qancha vaqt o'yinlar o'ynash imkoni bo'lmaydi.  Xizmat ko'rsatish yakunlangach Sizni yana ko'rishdan xursand bo'lamiz.",
  "incorrectGIN": "Hozirgi vaqtda, o'yin mavjud emas",
  "maintenance": "Tizimga texnik xizmat ko'rsatish. Uzr so'raymiz, hozirgi paytda tizimga texnik xizmat ko'rsatish ishlari olib borilmoqda. Xizmat ko'rsatish yakunlangach Sizni yana ko'rishdan xursand bo'lamiz. Minnatdorchilik bildiramiz",
  "sessionTimeout": "Faollik bo'lmaganligi sababli sizning seansingiz tugadi'",
  "ipBlocked": "Mahalliy qonunchilikka ko'ra, bu o'yinni sizning mamlakatingizda o'ynashning imkoni yo'q.",
  "notAvailable": "Not available"}