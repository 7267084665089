import { Application, Container, Sprite, Ticker } from 'pixi.js';
import BetDisk from './BetDisk';
import Radar from './Radar';
import YellowRay from './YellowRay';
import type { BetVO } from '../types';
import store from '@/store';
import SoundManager from './SoundManager';
import {gsap, Sine} from 'gsap';
import UFO from './UFO';
import Config from '@/Config';

export default class GameField extends Application {
    public static instance:GameField;
    public onReady!:()=>void;
    private mainContainer!:Container;
    private htmlContainer!:HTMLElement;
    private bg!:Sprite;
    private winPointer!:Sprite;
    private radar!:Radar;
    private ray!:YellowRay;
    private disk!:BetDisk;
    private ufoContainer!:Container;
    private raySpeed = 0.02;
    private rayAngle = 0;
    private sectoringDuration = 0;
    private onSectoringComplete!:()=>void;
    private addedBets!:Array<BetVO>;
    private soundManager :SoundManager;
    private mcWidth!:number;
    private mcHeight!:number;
    private RADIUS!:number;
    private copyAddedBets:any;
    public  onblurTime: any;
    public  onblurState: any;
    public  visibilitychange:any;
    private leftTime:any;


    constructor() {
        super({
            view: document.getElementById("gameFieldCanvas") as HTMLCanvasElement,
            backgroundAlpha: 0,
            antialias: true,
            autoDensity: true,
            sharedTicker: true            
        });
        this.addedBets = [];
        this.RADIUS = 140;
        this.onblurTime = new Date()
        this.onblurState = ''
       
        this.htmlContainer = document.getElementById("gameFieldCanvasContainer") as HTMLElement;
        this.resizeTo = this.htmlContainer;
        this.mainContainer = new Container();
        this.stage.addChild(this.mainContainer);
        this.mainContainer.alpha = 0;

        this.bg = Sprite.from(require("../assets/gameField/game_field_bg.svg"));
        this.bg.scale.set(0.54778);
        this.bg.anchor.set(0.5);
        this.mainContainer.addChild(this.bg);

        this.ufoContainer = new Container();
        this.mainContainer.addChild(this.ufoContainer);

        this.disk = new BetDisk();
        this.mainContainer.addChild(this.disk);
        
        this.radar = new Radar();
        this.mainContainer.addChild(this.radar);

        this.ray = new YellowRay();
        this.mainContainer.addChild(this.ray);        

        this.winPointer = Sprite.from(require("../assets/gameField/pointer.svg"));
        this.winPointer.position.set(130, -130);
        this.winPointer.scale.set(0.54778);
        this.winPointer.anchor.set(0.5);
        this.mainContainer.addChild(this.winPointer);
        this.leftTime = store.state.room.game.time.left

        this.disk.onRotationState = (state, duration)=> {
            gsap.killTweensOf(this.winPointer);
            switch(state) {
                case 0:
                    gsap.to(this.winPointer, {angle: 16, duration: duration});
                    break;
                case 1:
                    gsap.to(this.winPointer, {angle: -16, duration: 0.5, yoyo:true, repeat:-1, ease: Sine.easeInOut});
                    break;
                case 2:
                    gsap.to(this.winPointer, {angle: 0, duration: duration});
                    break;
            }
            this.winPointer;
        }
        
        this.soundManager = new SoundManager()

        // console testing
        //(window as any).GGtest = this;

        window.onresize = ()=> { this.onResize(); }
        this.testSize();

        window.onblur = ()=>{
            this.onblurCheck()
        }

        window.onfocus = () => {
            this.onfocusCheck()
        }

        window.addEventListener("visibilitychange", ()=> {
           document.visibilityState === 'visible'? this.onfocusCheck() : this.onblurCheck() 
           this.visibilitychange = document.visibilityState === 'visible'? true: false;           
        });        

    }

    private onfocusCheck(){
         // this.visibilitychange && this.socetClosed()
         Config.IS_LOG_STORE && console.log("window  onfocus");  
         let time: any = new Date()        
         let state = store.state.room.game.stage           
         switch (state) {                
             case 'idle':                    
                 break
             case 'trade':
                 break    
             case 'landing':
                 break
             case 'roll':
                 this.clearText()
                 if(this.onblurState == 'landing'){
                    // console.log(' case roll   landing)'); 
                    // console.log(time-this.onblurTime);
                    // console.log(this.leftTime );
                    // console.log("time -  this.leftTime - this.onblurTime" + (time - this.leftTime - this.onblurTime));
                    this.disk.dickUpdateTime(time - this.leftTime - this.onblurTime);
                    this.clearText()
                 }
                 if(this.onblurState == 'trade'){
                    // console.log('  case roll  this.onblurState == trade)'); 
                    // console.log(time-this.onblurTime);
                    // console.log(store.state.stageTotalTime.landing );
                    // console.log(this.leftTime );
                    // console.log("time - store.state.stageTotalTime.landing - this.leftTime - this.onblurTime" + (time - store.state.stageTotalTime.landing - this.leftTime - this.onblurTime ));
                    
                    this.disk.dickUpdateTime(time - store.state.stageTotalTime.landing - this.leftTime - this.onblurTime);
                    this.clearText()
                 }
                 if(this.onblurState == 'roll'){
                     console.log(' case roll  this.onblurState == roll)');  
                     this.disk.dickUpdateTime(time - this.onblurTime);
                 }
                 break        
             case 'award':
                 this.clearText()
                 gsap.killTweensOf(this.winPointer);
                 this.disk.dickUpdateTime(11000); 
                 if(this.onblurState == 'landing' || this.onblurState == 'trade'){
                    console.log(' case award  landing || store.state.room.game.stage == trade)');  
                    this.disk.clear()
                    this.instantDrawSectors_RecoveryState()
                    // this.disk.dickUpdateTime(11000); 
                 }
                 if(this.onblurState == 'roll' || store.state.room.game.stage == 'award'){
                     console.log(' case award  roll || store.state.room.game.stage == award)');  
                    //  this.disk.dickUpdateTime(11000);
                 }                    
                 break
                }

            // if(store.state.room.game.stage == 'award'){
            // this.disk.dickUpdateTime(11000);           
            // // this.instantDrawSectors_RecoveryState()  
            // // this.instantDiskPosition(Math.PI*0)  
            // this.clearText()
            // // this.dickStopAndClear()
            // }
            // if(store.state.room.game.stage == 'roll'){
            //     // this.disk.clear()
            //     // this.instantDrawSectors_RecoveryState()
            //     this.clearText()
            // }           
            // let time: any = new Date()
            // // console.log(time);
            // // console.log(this.onblurTime);            
            // this.disk.dickUpdateTime(time - this.onblurTime);
            // // this.instantDiskPosition(0)
    }

    private onblurCheck(){
        this.onblurTime = new Date()
        let lostPath =  store.state.stageTotalTime.pathDate.getTime()
        let state = store.state.room.game.stage 
        this.leftTime = lostPath + store.state.room.game.time.left - this.onblurTime
        // console.log("----------------------------------", this.leftTime);
        switch (state) {                
            case 'idle':             
                this.onblurState = 'idle'
                break
            case 'trade':
                // this.leftTime = store.state.room.game.time.left
                this.onblurState = 'trade'
                break    
            case 'landing':
                // this.leftTime = store.state.room.game.time.left
                // console.log('landinglanding',this.leftTime);                
                this.onblurState = 'landing'
                break                    
            case 'roll':                
                this.onblurState = 'roll'
                break        
            case 'award':
                this.onblurState = 'award'
                break
        }           
        Config.IS_LOG_STORE && console.log("window onblur");            
    }

    public dickUpdateTime(t:number){
        this.disk.dickUpdateTime(t);
    }

   
    private socetClosed(){
        let time: any = new Date();
        (time-this.onblurTime)>15000?store.commit("M_DEFAULTSOCKETCLOSE", true):null
    }

    private calcAngle() {
        Config.IS_LOG_STATE && console.log("$$ calcAngle:"+store.state.room.game.arrowPosition);
        
        let dbPI = Math.PI*2;
        let P = Math.floor(store.state.room.game.arrowPosition / dbPI);
        let R = store.state.room.game.arrowPosition % dbPI;
        return dbPI - R + P*dbPI;
    }

    private testSize() {
        // console.log("test size");
        // this.init();
        if(this.stage.width > 200 && this.stage.height > 200) this.init();
        else setTimeout(()=>{ this.testSize(); }, 100);
    }

    private init() {
        // console.log("GameField init");
        this.mcWidth = this.mainContainer.width;
        this.mcHeight = this.mainContainer.height;
        this.onResize();
        this.mainContainer.alpha = 1;
        this.ray.setAngle(this.rayAngle);
        if(this.onReady != null) this.onReady();
        setInterval(()=>{this.onResize()},0)
    }   

    private onResize() {
        this.resize();
        this.stage.x = this.renderer.width/2;
        this.stage.y = this.renderer.height/2;
        let cw = Config.scaleCanvas*this.renderer.width / 380 //this.mcWidth;
        let ch = Config.scaleCanvas*this.renderer.height / 380 //this.mcHeight;
        if(cw <= ch) this.stage.scale.set(cw);
        else this.stage.scale.set(ch);
        // console.log("this.mcWidth ",this.mcWidth, "   cw  ",  cw );
    }

    private updateRadar() {
        this.rayAngle += this.raySpeed * Ticker.shared.deltaTime;
        this.ray.setAngle(this.rayAngle);
        this.radar.setAngle(this.rayAngle);            
    }

    ////////////////////////////

    public radarOn() {
        this.destroyStaticUFO()       
        // this.soundManager.soundStopAll()
        // store.state.optionSound && this.soundManager.sound.play("nachalo" ,{loop: true})    
        if(!this.radar.isActive) {                
            Ticker.shared.add(this.updateRadar, this);
        }
        this.radar.isActive = true;
        this.radar.show();
    }

    public radarOff() {
        Ticker.shared.remove(this.updateRadar, this);
        this.radar.isActive = false;
        this.radar.hide();
    }

    

    public setAddedBets(bets:Array<BetVO>) {
        this.addedBets = bets.slice();
    }

    public setCopyAddedBets(bets:Array<BetVO>) {
        this.copyAddedBets = JSON.parse(JSON.stringify(bets.slice()))
    }

    private createStaticUFOPosition() {
        var ufo = new UFO(this.ufoContainer, false);
        var r = (this.RADIUS - 40) * Math.random() + 20;
        var a =  Math.random()* Math.PI *2
        ufo.x =  r * Math.cos(a);
        ufo.y =  r * Math.sin(a);        
    }

    public createStaticUFO(angle:number) {        
        if(Math.random() < 0.2)  this.createStaticUFOPosition();
    }

    public setCollorStaticUFO(angle:number) {        
        
    }

    public destroyStaticUFO() {        
        this.ufoContainer.removeChildren()       
    }

    public startDiskSectoring(duration:number, onComplete:()=>void) { 
        Ticker.shared.add(this.createStaticUFO, this);  
        // this.soundManager.soundStopAll()
        // store.state.optionSound && this.soundManager.sound.play("razlojka",{loop: true})      
        this.sectoringDuration = duration;
        this.onSectoringComplete = onComplete;
        let zeroStartAngle = Math.PI*store.state.room.game.zero; // пол-сектора zero, 2π*zero/2 = π*zero;
        this.rayAngle = 0 + zeroStartAngle;
        this.disk.startPosition(zeroStartAngle);
        this.ray.setAngle(this.rayAngle);
        this.disk.setAngle(0);
        this.nextSector();   
    }

    public startDiskRolling(duration:number, arrowPosition:number) {
        Config.IS_LOG_DATE && console.log(' |_GameField___startDiskRolling');
        
        if(store.state.room.game.stage == "award" )  duration = 0 
        Ticker.shared.remove(this.createStaticUFO, this);  
        Ticker.shared.add(this.setCollorStaticUFO, this);
        this.soundManager.soundStopAll()
        //  store.state.optionSound &&  this.soundManager.sound.play("vrascheniye_barabana")   // see  in  GameFieldCmp.vue
        let diskWinAngle = arrowPosition + Math.PI/4; // π/4 - угол между нулевой позицией диска и треугольной стрелкой
        this.disk.setAngle(diskWinAngle, duration);
        this.ticker.update();
        this.clearRay();
    }

    public instantDiskPosition(arrowPosition:number) {
        let diskWinAngle = arrowPosition + Math.PI/4;
        this.disk.setAngle(diskWinAngle, 0);
        this.clearRay();
    }

    public instantDrawSectors() {
        Config.IS_LOG_DATE && console.log("  |_GameField__instantDrawSectors: ");
        let zeroStartAngle = Math.PI*store.state.room.game.zero;
        this.disk.startPosition(zeroStartAngle);
        for (let i = 0; i < this.addedBets.length; i++) {
            const betData = this.addedBets[i];
            this.disk.addBetSector(betData, 0);
        }
        this.addedBets.length = 0;
    }

   

    public diskToStart() {
        this.disk.setAngle(0);
    }
    public dickStopAndClear() {
        gsap.killTweensOf(this.winPointer);
        this.disk.dickStopAndClear();
    }

    public clearRay() {
        this.ray.visible = false;
        this.rayAngle = 0;
        this.ray.setAngle(this.rayAngle);
    }

    public clearBets() {
        this.soundManager.soundStopAll()
        this.disk.clear();
        this.disk.setAngle(this.rayAngle);
        this.ray.visible = true;
        this.sectoringDuration = 0;
        this.addedBets.length = 0;
    }

    public clearText() {
        this.disk.clearText()
    }


    public instantDrawSectors_RecoveryState(drawText:boolean = false) { 
        if(this.copyAddedBets?.length) {
            Config.IS_LOG_DATE && console.log(" |_GameField___instantDrawSectors_RecoveryState");
            let zeroStartAngle = Math.PI*store.state.room.game.zero;
            this.disk.startPosition(zeroStartAngle);
            for (let i = 0; i < this.copyAddedBets.length; i++) {
                const betData = this.copyAddedBets[i];
                this.disk.addBetSector(betData, 0);
            }
            // this.copyAddedBets.length = 0;
            this.addedBets.length = 0 
            drawText?null: this.disk.clearText();    
       }
    }

    ////////////////////////////

    private nextSector() {            
        this.soundManager?.soundStopAll()
        store.state.optionSound && this.soundManager.sound?.play("razlojka")       
        if(this.addedBets.length == 0) {
            this.onSectoringComplete();
            return;
        }
        let betData = this.addedBets.shift();
        let animTimeSec = betData!.part*this.sectoringDuration/1000;
        this.rayAngle += betData!.part*Math.PI*2;
        if (store.state.room.game.stage== 'roll') animTimeSec = 0.0001
        this.ray.setAngle(this.rayAngle, animTimeSec, ()=>{
            this.nextSector();
        }, true);
        this.disk.addBetSector(betData!, animTimeSec*0.8);
        if (store.state.room.game.stage== 'roll')this.clearText()        
        // ###2
        // switch(store.state.room.game.stage){
        // case  "roll":
        //     this.disk.clear()
        //     this.instantDrawSectors_RecoveryState(false)
        //     break
        // case  "award":
        //     // this.instantDrawSectors_RecoveryState(false)
        //     break
        // default:
        //     this.soundManager?.soundStopAll()
        //     store.state.optionSound && this.soundManager.sound?.play("razlojka")       
        //     if(this.addedBets.length == 0) {
        //         this.onSectoringComplete();
        //         return;
        //     }
        //     let betData = this.addedBets.shift();
        //     let animTimeSec = betData!.part*this.sectoringDuration/1000;
        //     this.rayAngle += betData!.part*Math.PI*2;
        //     this.ray.setAngle(this.rayAngle, animTimeSec, ()=>{
        //         this.nextSector();
        //     }, true);
        //     this.disk.addBetSector(betData!, animTimeSec*0.8);
        //     break
        // }
        // ###1
        // if(store.state.room.game.stage == "roll" ) {  //!= 'landing' == "roll" 
        //     this.instantDrawSectors_RecoveryState(false)
        // }else{
        //     this.soundManager?.soundStopAll()
        //     store.state.optionSound && this.soundManager.sound?.play("razlojka")       
        //     if(this.addedBets.length == 0) {
        //         this.onSectoringComplete();
        //         return;
        //     }
        //     let betData = this.addedBets.shift();
        //     let animTimeSec = betData!.part*this.sectoringDuration/1000;
        //     this.rayAngle += betData!.part*Math.PI*2;
        //     this.ray.setAngle(this.rayAngle, animTimeSec, ()=>{
        //         this.nextSector();
        //     }, true);
        //     this.disk.addBetSector(betData!, animTimeSec*0.8);
        // }       
    }

}

