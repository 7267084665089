
import { defineComponent } from 'vue';
import i18n from '../../i18n';
import store from '../../store';
import { useSound } from '@vueuse/sound';
import btnPushSfx from '../../assets/audio/najatie.mp3';

export default defineComponent({
    name: 'ChangeAvatar',
    icon: '',       
    data() {
        return  {            
            icon: "000"        
        }
    }, 
    computed: {
        Ok():string { return i18n.global.t(' Ok'); },
        cancel():string { return i18n.global.t('cancel'); },
        currentIcon():string {
            return this.icon;
            // return store.state.user.icon;
        }  
    },
    setup() {        
        const btnPush = useSound(btnPushSfx, {volume: 1.0,interrupt: false}).sound;
        return {btnPush};
    },
    mounted(){
    // console.log('----------------------------------------t setting')
    },
    methods: {
        selectIcon(num:string) {
            let n: any = num.toString().length           
            let i: string = n ?  n==1 ? "00"+num: n==2 ? "0"+num: n==3 ? num : '' : '';
            this.icon = i;
        },        
        sendIcon(){
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_ICON_POPUP_IS_OPENED', !store.state.isOpenChangeAvatar);
            store.commit('M_POPUP_IS_OPENED', true);
            store.dispatch('CHANGE_ICON', this.icon);
            store.commit('M_CHANGE_ICON', this.icon);
        },
        sendCancel(){
            store.state.optionSound && this.btnPush.play()
            store.commit('M_CHANGE_ICON_POPUP_IS_OPENED', !store.state.isOpenChangeAvatar);
            store.commit('M_POPUP_IS_OPENED', true);
        }
    }
});


